export default function validateInfo(values) {
  let errors = {};

  var pattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/i;

  //firstname
  if (values.name && values.name.trim() === "") {
    errors.name = "*Please enter user name";
  } else if (values.name === null) {
    errors.name = "*Please enter user name";
  } else if (values.name === undefined) {
    errors.name = "*Please enter user name";
  } else if (values.name === "") {
    errors.name = "*Please enter user name";
  }

  if (values.email && values.email.trim() === '') {
    errors.email = '*Please enter emailid';
  } else if (values.email === undefined) {
    errors.email = '*Please enter emailid';
  } else if (!pattern.test(values.email)) {
    errors.email = "*Please enter valid email address.";
  }

  // mobile
  // if (typeof values["mobile"] !== "undefined") {
  //   const patternMobile = new RegExp(/^[0-9\b]+$/);
  //   if (!patternMobile.test(values["mobile"])) {
  //     //   isValid = false;
  //     errors["mobile"] = "*Please enter only number.";
  //   } else if (values["mobile"].length !== 11) {
  //     //   isValid = false;
  //     errors["mobile"] = "*Please enter valid mobile number.";
  //   }
  // }

  return errors;
}
