import React from "react";
// import "../App.css"
import NotFoundImg from "../assets/img/404.jpg";
const NotFound = () => {
  return (
    <div>
      <section className="bg_img">
      </section>
      <div>
      <img className="notFounnd" src={NotFoundImg} alt="website designing company" />
      </div>
    </div>
  );
};
export default NotFound;
