import React from "react";
import "./AndroidDevelopment.css";
import "./animate.css";
import brandsuccess1 from "../../assets/img/brand-success1.png";
import brandsuccess2 from "../../assets/img/brand-success2.png";
import m1 from "../../assets/img/m1.svg";
import m2 from "../../assets/img/m2.svg";
import m3 from "../../assets/img/m3.svg";
import wp1 from "../../assets/img/wp1.svg";
import wp2 from "../../assets/img/wp2.svg";
import wp3 from "../../assets/img/wp3.svg";
// import wp4 from "../../assets/img/wp4.svg";
import lineshape from "../../assets/img/line-shape.png";
import { Helmet } from "react-helmet";
const IOTDevelpment = () => {
  return (
    <>
      <Helmet>
        <title>IOT App Development Services | Hire IOT App Developers</title>
        <meta name="title" content="IOT App Development Services | Hire IOT App Developers" />
        <meta name="keywords" content="IOT App Development Services, Hire IOT App Developers, IoT app development " />
        <meta name="description" content="Discover the power of IoT app development with Elicit Digital. We transform your business with innovative solutions tailored to your needs. Get a free quote!" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="IoT App Development Services | Hire IoT App Developers " />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Discover the power of IoT app development with Elicit Digital. We transform your business with innovative solutions tailored to your needs. Get a free quote!" />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="IoT App Development Services | Hire IoT App Developers " />
        <meta name="twitter:description" content="Discover the power of IoT app development with Elicit Digital. We transform your business with innovative solutions tailored to your needs. Get a free quote!" />
      </Helmet>
   <section className="bg_img">
      </section>
      <section className="service-banner">       
        <div className="container">
          <div className="col-lg-12 offset-lg-12 col-md-12" >
            <div className="section-title-area text-center">
              <h1 className="section-title-main">Services Mobile App<span class="technolgy_focus"> Development</span></h1>
              <p className="services-text">At our company a team of devoted and skilled developers blends their skills</p>
              <div className="service-contact text-center" >
                <a class="cont-btn" href="/get-in-touch">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-success-area section">
        <div className="container">
          <div className="brand-success-wrap">
            <div className="brand-success-top">
              <div className="row">
                <div className="col-lg-6 align-self-center order-1 order-lg-0">
                  <div className="success-top-left">
                    <h2>iOS Development</h2>
                    <p>We have a team of innovative and creative who delivers a quick, full-
featured, novel, and secured iOS app at an affordable price. They provide 
undisputable solutions, consistent high-quality output on time that 
accelerates cent percent customer’s satisfaction. </p>
<p> Work of our developers execute the international standards. Using the newest technology, our 
professional iOS app developers have created dynamic and secure apps 
for iOS / iPhone / iPad solutions.    </p>
                    <ul className="feature-list">
                      <li>
                        <img className="icon" src={m1} alt="feature" />This contributes in enhancement of your 
brand reputation and your business.
                      </li>
                      <li>
                        <img className="icon" src={m2} alt="feature" />Our developers can offer services 
which is best suited for all business sizes
                      </li>
                      <li>
                        <img className="icon" src={m3} alt="feature" />And you will never regret for 
choosing to support your start-ups r enterprise. 
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-1">
                  <div className="success-top-right wow " style={{visibility: 'visible'}}>
                    <img src={brandsuccess1} alt="brand-success" />
                  </div>
                </div>
              </div>
            </div>
            <div className="brand-success-bottom">
              <div className="row">
                <div className="col-lg-4 align-self-center">
                  <div className="success-bottom-left wow " style={{visibility: 'visible'}}>
                    <img src={brandsuccess2} alt="brand-success" />
                    <h2>Hybrid App Development</h2>
                    <p>Elicit Digital ranks as one of the best Hybrid App Development Company. 
The company designs a mobile software where single coding can work for 
several platforms that exist for smartphones like Android, iOS, Windows, 
etc. The company use single code-base to access different mobile 
platforms. The Cross-platform app development has taken off widely.</p>
<p>Our expert works proficiently on some major cross-platform mobile 
development technologies like React native, Xamarin, and Flutter.</p>
                  </div>
                </div>
                <div className="col-lg-8 align-self-center">
                  <div className="success-bottom-right features-list">
                    <div className="sigle-feature">
                      <div className="media">
                        <div className="icon mr-4">+</div>
                        <div className="media-body">
                          <h3 className="feature-title mt-0 ">React Native </h3>
                          <p className="feature-content">Our experts at Elicit Digital are experienced and skilled to 
build a robust and unique react native app for their clients within a specific
schedule. The team delivers a dream product by maintaining the quality 
and gives solution to every relevant problems.</p>
                        </div>
                      </div>
                    </div>
                    <div className="sigle-feature">
                      <div className="media">
                        <div className="icon mr-4">+</div>
                        <div className="media-body">
                          <h3 className="feature-title mt-0 ">Xamarin</h3>
                          <p className="feature-content">The Xamarin developers of our firm works flawlessly by using C,
C#, and .Net frameworks. They develop unique UIs for one platform and 
can write some elements which can be used for another platform.</p>
                        </div>
                      </div>
                    </div>
                    <div className="sigle-feature">
                      <div className="media">
                        <div className="icon mr-4">+</div>
                        <div className="media-body">
                          <h3 className="feature-title mt-0 ">Flutter</h3>
                          <p className="feature-content">Emerging as one of the top Flutter app development company 
offering robust cross-platform services. We have a team of experts 
standing out as problem-solvers and catering customized quality solutions 
to enhance your business. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work-progres-two pd-bottom-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area text-center">
                <span className="section-sub-title">Android App Development</span>
                <h2 className="section-title">At our company a team of devoted</h2>
                <p className="section-text">skilled developers blends their 
skills with the cutting-edge mobile technology almost at all platforms. We 
can provide our clients a perfect solution to accomplish all demands of 
android development services. Android is the first choice of the start-ups 
and enterprises. We provide a robust internal defence system enhancing 
the security to or client’s data from prying eyes by wrapping it in 
encryption. 
The company offers a few reasons to build an android app:</p>
              </div>
            </div>
          </div>
          <div className="work-list">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" style={{visibility: 'visible', animationName: 'zoomIn'}}>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <img src={wp1} alt="" />
                  </div>
                  <h3>Cost- effective Platform</h3>
                  <p>Android developers have easy access to tools and systems, and end 
devices and hardware are   considerably less expensive. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn'}}>
                  <div className="icon d-flex justify-content-center align-items-center">
                  <img src={wp2} alt="" />
                  </div>
                  <h3>Quick and Easy to Develop</h3>
                  <p>The availability of the simple functioning model speeds up the 
development of apps.  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'zoomIn'}}>
                  <div className="icon d-flex justify-content-center align-items-center">
                  <img src={wp3} alt="" />
                  </div>
                  <h3>Advantage of Android App </h3>
                  <p>Higher ROI with Lower Costs ,Faster Deployment ,Target Multiple Platforms ,Versatility and Scalability ,Enhanced Security ,Customization </p>
                </div>
              </div>
            </div>
            <div className="line-shape">
              <img src={lineshape} alt="line-shape" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default IOTDevelpment;
