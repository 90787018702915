import React from "react";
import Logo from "../../assets/img/logo.png";
import { ImLocation } from "react-icons/im";

const VCard = () => {
  return (
    <div className="vcard">
      <div className="contact-card">
        <div className="card-header">
          <a href="https://elicit.digital/" target="blank">
            {" "}
            <img
              src={Logo}
              alt="Profile"
              className="Logo"
              style={{ width: "200px" }}
            />
          </a>
        </div>
        <div className="card-body">
          <h2 className="name">Aakash Singhal</h2>
          <h4 className="position">CEO/Founder</h4>
          <a href="https://elicit.digital/">
            {" "}
            <p className="company">Elicit Digital</p>
          </a>
          <p className="description">
            Meet Aakash, a visionary leader dedicated to providing exceptional services and driving strategic growth for our clients and partners. </p>
          <div className="contact-info">
            <a href="tel:9111555876" title="+91 9111555876">
              <div className="d-flex">
                <div className="footer-location">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M426.666 330.667a250.385 250.385 0 0 1-75.729-11.729c-7.469-2.136-16-1.073-21.332 5.333l-46.939 46.928c-60.802-30.928-109.864-80-140.802-140.803l46.939-46.927c5.332-5.333 7.462-13.864 5.332-21.333-8.537-24.531-12.802-50.136-12.802-76.803C181.333 73.604 171.734 64 160 64H85.333C73.599 64 64 73.604 64 85.333 64 285.864 226.136 448 426.666 448c11.73 0 21.334-9.604 21.334-21.333V352c0-11.729-9.604-21.333-21.334-21.333z"></path>
                  </svg>
                </div>
                <div>
                  <p>+919111555876</p>
                </div>
              </div>
            </a>

            <a href="mailto:sales@elicit.digital" title="sales@elicit.digital">
              <div className="d-flex">
                <div className="footer-location">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                </div>
                <div>
                  <p>sales@elicit.digital</p>
                </div>
              </div>
            </a>
            <div className="d-flex">
              <div className="footer-location">
                <ImLocation />
              </div>
              <p>
                Office Plot No. 345, 346, <br />
                Floor : 3 Scheme 54 PU4, Indore, Madhya Pradesh 452010
              </p>
            </div>
          </div>
          <div className=" socile-media-icons-custom social-medias mt-4">
            <a
              href="https://www.linkedin.com/company/elicit-digital/"
              target="/_blank"
              className="linked">
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.instagram.com/elicit.digital/"
              target="/_blank"
              className="insta">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.youtube.com/@elicitdigital"
              target="/_blank"
              className="youtube">
              <i className="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
            <a
              href="https://in.pinterest.com/elicitdigital/"
              target="/_blank"
              className="pinterest">
              <i className="fa fa-pinterest" aria-hidden="true"></i>
            </a>
            <a
              href="https://twitter.com/elicitdigital"
              target="/_blank"
              className="twitter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{ fill: "rgb(255, 255, 255)", width: "18px" }}>
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/ElicitInfotech"
              target="/_blank"
              className="facebook">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default VCard;
