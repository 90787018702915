import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import './index.css';
import TestingConsistency from "../../../assets/img/Testing Consistency.png"
import PaymentGateway from "../../../assets/img/PaymentGateway.png"
import UtmostScalability from "../../../assets/img/UtmostScalability.png"
import Shape from "../../../assets/img/shape.png"


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function toggleTabbtn(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Specialization  = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: "100%" }} className="specialization-box">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        className="row service_tab"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Our Process" {...toggleTabbtn(0)} >
                        </Tab>
                        <Tab label="experience" {...toggleTabbtn(1)} />

                        <Tab label="UtmostScalability" {...toggleTabbtn(2)} />
                    </Tabs>
                   
                    
                </Box>

                <TabPanel value={value} index={0}>
                    <div id="content-1" className="shap-box" style={{position:"relative"}}>
                        <div id="content-1">
                        <div className="row">
                                <div className="col-lg-6 col-md-6 col-xm-12">
                                 <div className="image-special">
                                        <img src={TestingConsistency} alt="" />
                                 </div>
                                </div>
                                 <div className="col-lg-6 col-md-6 col-xm-12 pt-5">
                                    <h2 className="text-hire-hybrid">Testing Consistency of Hybrid App with UI/UX</h2>
                                    <p className="section-text-entrust p-0">When it comes to hybrid apps, there is often skepticism around their reliability regarding UX. However, our team of hybrid app developers at Elicit takes a different approach by prioritizing UI/UX testing methods across various mobile operating systems. We understand that user experience is key in driving engagement and loyalty, which is why we invest time and resources into thoroughly testing all UI changes across a wide range of mobile screens and resolutions.</p>
                                </div>
                        </div>             
                          </div>
                        <div className="shap">
                            <img src={Shape} alt="mobile app development service"/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div id="content-2" className="shap-box"  >
                        <div id="content-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xm-12">
                                    <div className="image-special">
                                        <img src={PaymentGateway} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xm-12 pt-5">
                                    <h2 className="text-hire-hybrid">Payment Gateway Solutions</h2>
                                    <p className="section-text-entrust p-0">Payment systems in a hybrid app play an integral role in facilitating secure and seamless transactions between customers and businesses. We understand the complexities of integrating payment gateways into mobile applications. To achieve this goal, we rely on various frameworks, plugins, or SDKs that help us connect to popular payment systems like Google Pay or Apple Pay for internal purchases. Our team of experienced developers works tirelessly to stay up-to-date with changes in native features so they can maintain the app's stability and ensure uninterrupted access to payment functions.</p>
                                </div>
                            </div>   
                        </div>
                        <div className="shap">
                            <img src={Shape} alt="Payment Gateway Solutions" />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <div id="content-2" className="shap-box" >
                        <div id="content-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xm-12">
                                    <div className="image-special">
                                        <img src={UtmostScalability} alt="Payment Gateway Solutions" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xm-12 pt-5">
                                    <h2 className="text-hire-hybrid">Utmost Scalability</h2>
                                    <p className="section-text-entrust p-0">As the world becomes more digitally connected, hybrid apps are increasingly becoming a preferred choice for developers and users alike. With utmost scalability at its core, hybrid app development has transformed from being catered to only a few users to attracting millions of people worldwide. Indians developers have recognized this trend and responded by enriching their hybrid app development services to optimize design, performance, and usability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="shap">
                            <img src={Shape} alt="hybrid apps" />
                        </div>
                    </div>
                </TabPanel>
             
            </Box>
        </>
    );
};
export default Specialization;
