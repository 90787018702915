import React from "react";
import {Helmet} from "react-helmet";

import "./AndroidDevelopment.css";
import "./animate.css";
import brandsuccess3 from "../../assets/img/brand-success3.png";
import wp1 from "../../assets/img/wp1.svg";
import wp2 from "../../assets/img/wp2.svg";
import wp3 from "../../assets/img/wp3.svg";
//import wp4 from "../../assets/img/wp4.svg";
import lineshape from "../../assets/img/line-shape.png";
import blogdetail from "../../assets/img/blog-detail.png";
import cloudcomputing from "../../assets/img/cloudcomputing.png"
import chatbot from "../../assets/img/chatbot.png"
import App from "../../assets/img/App.png"
import Socialmedia from "../../assets/img/Socialmedia.png"
import Digital from "../../assets/img/Digital.png"
import Shape from "../../assets/img/shape.png"
const WebDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Web Development Services | Web Development Agency</title>
        <meta name="title" content="Web Development Services | Web Development Agency" />
        <meta name="keywords" content="Web Development Services, Web Development Agency, Responsive Web Design, Website Design and Development, Hire App Developers" />
        <meta name="description" content="At Elicit Digital, find professional web development services catered to your requirements. Our skilled developers create a responsive website for you." />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Web Development Services | Web Development Agency" />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="At Elicit Digital, find professional web development services catered to your requirements. Our skilled developers create a responsive website for you." />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Web Development Services | Web Development Agency" />
        <meta name="twitter:description" content="At Elicit Digital, find professional web development services catered to your requirements. Our skilled developers create a responsive website for you." />
      </Helmet>
      <section className="bg_img">
      </section>
      <section className="service-banner">
        <div className="col-lg-12 offset-lg-12 col-md-12" >
          <div className="section-title-area text-center">
            <h1 className="section-title-main">Web <span class="technolgy_focus">Development</span></h1>
            <p className="services-text">Build your creditable and essential website with the most professional web development service.</p>
            <div className="service-contact text-center" >
              <a class="cont-btn" href="/get-in-touch">Contact Us</a>
            </div>
          </div>
        </div>
      </section>


      <section className="brand-success-area section">
        <div className="container">
          <div className="brand-success-wrap">
            <div className="brand-success-top">
              <div className="row">

                <div className="col-lg-6 order-0 order-lg-0">
                  <div className="success-top-right wow " style={{ visibility: 'visible' }}>
                    <img src={brandsuccess3} alt="Web Development Services" />
                  </div>
                </div>

                <div className="col-lg-6 align-self-center order-1 order-lg-0">
                  <div className="success-top-left">
                    <h2>Custom Web Development Services</h2>
                    <p>Custom <a href="/web-development-services">web development services</a> are becoming increasingly popular in
                      today's digital market. When your website needs to have more functionality
                      and features to stand out, a standard content management system (CMS) might
                      not suffice. This is where custom web development comes into play! At Elicit,
                      we specialize in building websites from scratch or customizing and integrating 3rd party
                      modules and software to suit your requirements. Our team of skilled developers takes a consultative
                      approach by listening to your vision, ideas, and needs to strategize an exceptional solution that meets
                      your project scope. Whether you are working on medium or large-scale projects, our technical experts
                      focus on delivering bespoke solutions tailored specifically for businesses' unique needs behind website
                      designing/development projects. With extensive experience in handling various industries' diverse demands
                      and an innovation-driven mindset, Elicit guarantees high-quality results and timely delivery every time!
                      Contact us today and let's make things happen together with our
                      top-notch custom web development services!</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>


      <section className=" custom-development">
        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-md-12">
              <div className="custom-web">
                <h2>Are you looking for a web development company in India? We develop an engaging website that your business needs. </h2>
                <p className="section-text-entrust-web">Choosing a <a href="/web-development-services">web development company</a> can be quite challenging as there are several options to choose from. It is important to avoid settling for less by finding an experienced team of web designers and developers with expertise in various programming languages and development platforms. When looking for a reliable web design and development company in India, it is imperative not to compromise on creativity or functionality.</p>
                <p className="section-text-entrust-web">At Elicit, we pride ourselves on having expert designers and developers who have helped countless businesses realize their digital presence for over a decade. Our skilled professionals at the web design & development firm dedicate time to exploring your business's needs before creating unparalleled solutions tailored specifically for you.</p>
                <p className="section-text-entrust-web">Beyond exceptional user interface designs, we also offer companies innovative website functionalities that make them stand out in today's hyper-competitive online market space while still maintaining high-quality customer service standards through automated chatbots or other technological advancements! Don't hesitate - contact our team today so we can help take your online branding efforts up a notch! </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="key-custom">
        <div className="container">
          <div className="success-bottom-right features-list">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="key-heading">
                  <h3>Key highlights</h3>
                </div>
              </div>
              <div className="row key-sigle">
                <div className="col-lg-6 col-md-6">
                  <div className="sigle-feature-one">
                    <div className="media">
                      <div className="icon mr-4">+</div>
                      <div className="media-body">
                        <h3 className="feature-title mt-0 ">Agile </h3>
                        <p className="feature-content">An Agile development process is a
                          revolutionary approach that promises to transform the way websites are developed and
                          launched. This methodology emphasizes collaboration, flexibility, and rapid iteration toward achieving the ultimate goal of
                          delivering high-quality products in record times.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="sigle-feature-one">
                    <div className="media">
                      <div className="icon mr-4">+</div>
                      <div className="media-body">
                        <h3 className="feature-title mt-0 ">Scalable</h3>
                        <p className="feature-content">We recognized that any successful
                          platform must be built with scalability in mind from its inception, and thus chose a reliable technology stack that would enable you
                          to expand our offering effectively over time.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="sigle-feature-one">
                    <div className="media">
                      <div className="icon mr-4">+</div>
                      <div className="media-body">
                        <h3 className="feature-title mt-0 ">Optimise</h3>
                        <p className="feature-content">We firmly believe that the
                          key to success in web development lies in optimisation. That's why we use frameworks that are designed to unlock top-tier performance; they allow us to squeeze
                          every last drop of speed and efficiency out of your website.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="sigle-feature-one">
                    <div className="media">
                      <div className="icon mr-4">+</div>
                      <div className="media-body">
                        <h3 className="feature-title mt-0 ">AWS</h3>
                        <p className="feature-content">AWS provides top-quality cloud
                          services to ensure your website or system stays up and running
                          with optimal speed and reliability. With AWS, you can rest assured that every
                          aspect of your network infrastructure is
                          being managed efficiently by a team of industry experts.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-detail-page section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="blog-details-left">
                <h2 className="post-title">Develop functional web solution with Elicit Approach</h2>
                <p className="post-content">6 Steps That Will Help Your Business Reaching Its Goals.</p>
                <aside className="sidebar">
                  <div className="widget widget-categories">
                    <ul>
                      <li>	Share your vision </li>
                      <li>Craft the design </li>
                      <li>Outshine Your Competition </li>
                      <li>Prepare for launch</li>
                      <li>Inspire your audience </li>
                      <li>Celebrate your success </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="blog-details-area">
                <div className="blog-thumbnail">
                  <img src={blogdetail} alt="Web Development Services" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wdevelopment-company" style={{ position: "relative" }}>
        <div className="features-animation">
          <div class="shape-img1">
            <img src={Shape} alt="Web Development Services" />
            </div>
        </div>
        <div className="container">
          <div className="row">


            <div className="col-lg-5 col-md-12">
              <div className="web-development">
                <span className=""><h3 className="section-web">Why custom web development? </h3></span>
                <p className="gathering-text-prag">Choosing a web development company can be quite challenging as there are several options to choose from. It is important to avoid settling for less by finding an experienced team of web designers and developers with expertise in various programming languages and development platforms. When looking for a reliable web design and development company in India, it is imperative not to compromise on creativity or functionality. </p>

              </div>
            </div>




            <div className="col-lg-7 col-md-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="technology-icon">
                    <div className="icon-image-first">
                      <div className="icon-first">
                        <img src={cloudcomputing} alt="Custom Web Development Services" />
                      </div>
                      <div>
                        <h5 className=" real-heading">cloud computing</h5>
                        <p className="cloud-text">Elicit's scalable cloud computing solution can help you rapidly grow and scale your business.</p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-6">
                  <div className="technology-icon">
                    <div className="icon-image-first">
                      <div className="icon-first">
                        <img src={App} alt="Custom Web Development Services" />
                      </div>
                      <div>
                        <h5 className=" real-heading">App</h5>
                        <p className="cloud-text">Save time and money with our experienced team that can be developed mobile applications across iOS and Android.  </p>
                      </div>
                    </div>
                  </div>
                </div>





                <div className="col-lg-6">
                  <div className="technology-icon">
                    <div className="icon-image-first">
                      <div className="icon-first">
                        <img src={Socialmedia} alt="Custom Web Development Services" />
                      </div>
                      <div>
                        <h5 className=" real-heading">Social media marketing</h5>
                        <p className="cloud-text">We make your digital marketing a breeze.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="technology-icon">
                    <div className="icon-image-first">
                      <div className="icon-first">
                        <img src={Digital} alt="Custom Web Development Services" />
                      </div>
                      <div>
                        <h5 className=" real-heading">Digital</h5>
                        <p className="cloud-text">Take your ideas from paper to digital reality. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="technology-icon">
                    <div className="icon-image-first">
                      <div className="icon-first">
                        <img src={chatbot} alt="Custom Web Development Services" />
                      </div>
                      <div>
                        <h5 className=" real-heading">chatbot</h5>
                        <p className="cloud-text">Integrate a chatbot that makes customers feel more connected and transform your website into a true voice of customers. </p>
                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>


          </div>
        </div>

      </section>




      <section className="expert-team ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="meet-expert text-center">
                {/* <div className="section-title-area"> */}
                <h3 className="meedt-heading">Meet Expert Team of Web Developers</h3>
                <p className="meet-text-prg">There's no better place to find sustained commitment than at elicit. We specialize in custom programming in all three web development types</p>

              </div>
            </div>
          </div>

          <div className="front-end">
            <div className="row">

              <div className="timeline">
                <div className="first-web-deveopers left-developer">
                  {/* <img src={frontend} alt="" /> */}
                  <div className="box-one">
                    <h5 className="web-heading-section" >Front-End</h5>
                    <p className="gathering-text-prag">Our front-end developer’s responsibility is to develop the look, feel, and interactive components of your site - elements that+ have become crucial in giving visitors an enjoyable user experience. Our talented developers work diligently behind the scenes utilizing HTML, CSS, JavaScript, React, AngularJS and the popular JQuery framework; all with one intention in mind - delivering a beautiful product that will leave a lasting impression on visitors whilst offering excellent usability
                      throughout their online journey.</p>
                    <span className="left-web-arrow"></span>
                  </div>
                </div>

                <div className="first-web-deveopers right-developer">
                  {/* <img src={Backend} alt="" /> */}
                  <div className="box-one">
                    <h5 className="web-heading-section" >Back-End</h5>
                    <p className="gathering-text-prag">Our back-end developers are crucial players who take care of everything server-side. They possess a wealth of expertise in writing codes that ensure seamless data flow between your website’s front and back-ends. With languages such as Java, PHP, Ruby and Ruby On Rails, C#, they have all it takes to build robust, efficient and scalable systems suitable for diverse businesses. And with reliable databases like MySQL, Postgres or MongoDB databases.</p>
                    <span className="right-web-arrow"></span>
                  </div>
                </div>


                <div className="first-web-deveopers left-developer">
                  {/* <img src={fullstack} alt="" /> */}
                  <div className="box-one">
                    <h5 className="web-heading-section" >Full-Stack</h5>
                    <p className="gathering-text-prag">We take pride in offering top-tier full-stack developers with vast experience in coding across multiple programming languages, along with an unmatched ability to craft both front-end and back-end components of websites. Whether you need a complex e-commerce platform or a simple website for your business, our talented developers can handle it all.</p>
                    <span className="left-web-arrow"></span>
                  </div>
                </div>

                <div className="first-web-deveopers right-developer">
                  {/* <img src={TechnologyStack} alt="" /> */}
                  <div className="box-one">
                    <h5 className="web-heading-section" >Our Technology Stack</h5>
                    <p className="gathering-text-prag">Selecting your perfect development stack can often be difficult. Our development team will be happy to help you through the process. All you have to do is ask.</p> <p className="Technology-prg">PHP | React | VueJS | Ruby | Nodejs | DotNet | Google Cloud | Azure | Angular | AWS</p>
                    <span className="right-web-arrow"></span>
                  </div>
                </div>
              </div>








              {/* <div className="col-lg-8 col-md-12">
                <div className="gathering-section-first">
                  <div className="gathering-image">
                    <img src={frontend} alt="" />
                  </div>
                  <div>
                    <h5 className="text-gathering text-left" >Front-End</h5>
                    <p className="gathering-text-prag">Our front-end developer’s responsibility is to develop the look, feel, and interactive components of your site - elements that+ have become crucial in giving visitors an enjoyable user experience. Our talented developers work diligently behind the scenes utilizing HTML, CSS, JavaScript, React, AngularJS and the popular JQuery framework; all with one intention in mind - delivering a beautiful product that will leave a lasting impression on visitors whilst offering excellent usability
                      throughout their online journey.</p>
                  </div>
                </div>
              </div> */}
              {/* second */}
              {/* <div className="col-lg-8 col-md-12">
                <div className="gathering-section-first">
                  <div className="gathering-image">
                    <img src={Backend} alt="" />
                  </div>
                  <div>
                    <h5 className="text-gathering text-left" >Back-End</h5>
                    <p className="gathering-text-prag">Our back-end developers are crucial players who take care of everything server-side. They possess a wealth of expertise in writing codes that ensure seamless data flow between your website’s front and back-ends. With languages such as Java, PHP, Ruby and Ruby On Rails, C#, they have all it takes to build robust, efficient and scalable systems suitable for diverse businesses. And with reliable databases like MySQL, Postgres or MongoDB databases.</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-8 col-md-12">
                <div className="gathering-section-first">
                  <div className="gathering-image">
                    <img src={fullstack} alt="" />
                  </div>
                  <div>
                    <h5 className="text-gathering text-left" >Full-Stack</h5>
                    <p className="gathering-text-prag">We take pride in offering top-tier full-stack developers with vast experience in coding across multiple programming languages, along with an unmatched ability to craft both front-end and back-end components of websites. Whether you need a complex e-commerce platform or a simple website for your business, our talented developers can handle it all.</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-8 col-md-12">
                <div className="gathering-section-first">
                  <div className="gathering-image">
                    <img src={TechnologyStack} alt="" />
                  </div>
                  <div>
                    <h5 className="text-gathering text-left" >Our Technology Stack</h5>
                    <p className="gathering-text-prag">Selecting your perfect development stack can often be difficult. Our development team will be happy to help you through the process. All you have to do is ask.</p> <p className="Technology-prg">PHP | React | VueJS | Ruby | Nodejs | DotNet | Google Cloud | Azure | Angular | AWS</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>










      <section className="work-progres-two ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title-area text-center">
                <span className="section-sub-title">Web Development</span>
                <h2 className="section-title">Custom Web Development Services</h2>
                <p className="section-text">Custom web development services are becoming increasingly popular in today's digital market. When your website needs to have more functionality and features to stand out, a standard content.</p>
              </div>
            </div>
          </div>
          <div className="work-list">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <img src={wp1} alt="Web Development Services" />
                  </div>
                  <h3>Elicit Digital Company </h3>
                  <p>are all aware that software development
                    foundations must be established in a methodical, scalable, clean,
                    performance-centric, and safe manner.The MVC-enabled AnjularJS
                    framework allows for a methodical implementation of the web application. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn' }}>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <img src={wp2} alt="Web Development Services" />
                  </div>
                  <h3>rundown of fantastic</h3>
                  <p> administrations by our group of
                    exceptionally energetic and devoted Angular Developers who are known
                    to make intuitive web applications assisting you with meeting the ideal
                    business objective.  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-work text-center wow zoomIn" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'zoomIn' }}>
                  <div className="icon d-flex justify-content-center align-items-center">
                    <img src={wp3} alt="Web Development Services" />
                  </div>
                  <h3>Web Development </h3>
                  <p>Build your creditable and essential website with the most professional web development service </p>
                </div>
              </div>
            </div>
            <div className="line-shape">
              <img src={lineshape} alt="Web Development Services" />
            </div>
          </div>
        </div>
      </section>



      {/* dummy */}


    </div>
  );
};
export default WebDevelopment;
