import React from "react";
import "../../App.css";
import { Link } from "@material-ui/core";
const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <section className="privacy-bg relative">
        <div className="topbgimage-policy">
          <h2>Web And Mobile Application Development Company</h2>
        </div>
        <div className="free-audti mt-4">
          <a class="get_btn py-3 px-3" href="/get-in-touch">
            Get in Touch
          </a>
        </div>
      </section>

      <div className="container mt-5">
        {/* <div class="boxLoadingyellow"></div> */}
        <div class="yellowBoxShow"></div>
        <div class="blueboxanimation"></div>
        <div class="leftBorder"></div>
        <div className="privacy-page mt-5 mb-4">
          <div>
            <ul className="d-flex breadcrumb-privacy">
              <li>
                <Link href="/">HOME</Link>
              </li>
              <li>
                <Link href="/privacy-policy">PRIVACY POLICY</Link>
              </li>
            </ul>
          </div>
          <h1>PRIVACY POLICY</h1>
          <div>
            <h3>Our Commitment to Privacy</h3>
            <p>
              In order to protect your privacy, we provide this notice
              explaining our online information practices and the choices you
              can make about how your information is collected and used. We
              provide this notice on every page of our website and whenever we
              ask for personally identifiable information in order to make it
              easy to find.
            </p>
          </div>
          <div>
            <h3>The Information We Collect</h3>
            <p>
              Eilcit Digital website. On some pages, you can make requests for a
              demo of any kind of Software or Application. The types of personal
              information collected on these pages are:
            </p>
            <ul>
              <li>Name</li>
              <li>Company name</li>
              <li>Address</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Web address</li>
              <li>Descriptions of business issues</li>
            </ul>
          </div>
          <div>
            <h3>The Way We Use Information</h3>
            <p>
              information provided to us in ways unrelated to the ones described
              above. We collect personal and business information from you for
              the sole purpose of addressing your inquiries effectively. The
              email addresses we obtain are solely used to respond to your
              emails and are not shared with any external parties. Additionally,
              we utilize non-identifiable and group data to enhance our
              website's layout. This involves monitoring the level of interest
              in various service areas by analyzing page visits. Furthermore, we
              employ aggregate data acquired through our customized tools. Rest
              assured that we neither use nor disclose your information in any
              manner inconsistent with what is explained here.{" "}
            </p>
          </div>
          <div>
            <h3>Our Commitment to Data Security</h3>
            <p>
              We have implemented appropriate physical, electronic, and
              managerial procedures to safeguard and secure the information we
              collect online in order to prevent unauthorized access, maintain
              data accuracy, and ensure the correct use of this information.
            </p>
          </div>
          <div>
            <h3>Our Commitment to Children’s Privacy</h3>
            <p>
              As a result, we don't collect or maintain information on our
              website from anyone we know is under 11, and none of our website
              is structured to appeal to anyone under 10.
            </p>
          </div>
          <div>
            <h3>How You Can Access or Correct Your Information</h3>
            <p>Email us if you would like us to delete your information.</p>
          </div>
          <div>
            <h3>How to Contact Us</h3>
            <p>
              Contact us at{" "}
              <a href="tel:9111555876" title="+91 9111555876">
                +91-9111555876{" "}
              </a>
              or
              <a
                href="mailto:sales@elicit.digital"
                title="sales@elicit.digital"
              >
                {" "}
                sales@elicit.digital
              </a>{" "}
              if you have any questions or concerns about these privacy
              policies.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
