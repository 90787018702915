import React from "react";
import "./index.css";
import { Accordion } from "react-bootstrap";

const AccordionsIos = () => {
    return (
        <div className="accordion_container container Accordian-ios">
           
            <Accordion>
                <div className="row">
                    <div className="col-lg-12 col-md-12 acordion_row">
                        <Accordion.Item className="Accordion.Item" eventKey="14">
                            <Accordion.Header>IOS Development Consulting</Accordion.Header>
                            <Accordion.Body>
                                <p className="section-text-accordian">If you're someone who needs an iOS app but is unsure about the features it should contain, don't worry. You can now easily get the best guidance from our experts in iOS development consulting. You can ensure that your app meets all of your requirements and objectives with us. Our experienced consultant will work closely with you to understand your vision for the app and suggest creative ideas that align with both your business goals and user expectations.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>IOS Applications Testing & Maintenance</Accordion.Header>
                            <Accordion.Body>
                                <p className="section-text-accordian">At our company, iOS Applications Testing & Maintenance is at the forefront of our iPhone mobile development process. We understand that security and performance are paramount when it comes to creating successful applications, which is why we have implemented a rigorous testing protocol for every project we undertake. Our team of experts utilizes a combination of manual and automated testing to ensure that your application runs smoothly and remains immune to any hacks or glitches.</p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="16">
                            <Accordion.Header>IOS Custom Application Development & Design</Accordion.Header>
                            <Accordion.Body>
                                <p className="section-text-accordian">We pride ourselves on being experts in iOS custom application development and design. We understand that businesses across all industries and geographies have unique needs, which is why we specialize in providing customized solutions that address those specific requirements. Our team of skilled developers has years of experience creating software for start-ups, established enterprises, and everything in between. Whether you need a brand-new app or an update to an existing one, we can deliver results that meet your expectations. From the initial planning stages to final deployment and support, we work closely with each client to ensure their complete satisfaction throughout every step of the process.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="17">
                            <Accordion.Header>Hybrid IPhone App Development</Accordion.Header>
                            <Accordion.Body>
                                <p className="section-text-accordian">Hybrid iPhone App Development, businesses now have a unique opportunity to reach out to audiences across different platforms while ensuring maximum engagement and user experience. Our team of expert iPhone Developers brings years of experience in developing cross-platform hybrid apps for iOS, Android, and Windows. We are adept at designing innovative solutions that cater to diverse business needs while leveraging modern technologies such as HTML5 and CSS3.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </div>

                    
                       
                        
                    </div>
                
            </Accordion>
        </div>
    );
};
export default AccordionsIos;
