 import React, { useState } from "react";
import "./GetTouch.css";
// import Get_Useform from "./Get-Touch Validation/Get_Useform"
// import Get_Validation from './Get-Touch Validation/Get_Validation'
import { IoMdCall } from "react-icons/io"
import file_icon from "../../../assets/img/file-icon.svg";
import skype from "../../../assets/img/skype.svg";
import messanger from "../../../assets/img/messanger.svg";
import instagram from "../../../assets/img/instagram-blue.svg"
import { ImLocation } from "react-icons/im";
import { FiMail } from "react-icons/fi";
import axios from 'axios';
// import { useState } from "react";
import CareerPage from "../../../assets/img/career-page.jpg"
import { Helmet } from "react-helmet";
const GetTouch = () => {
  
//  const { handleChange,  handleSubmit, errors, onFIleChangeHandler } = Get_Useform(Get_Validation);
 

 const [formDatagetintotuch, setFormDatagetintotuch] = useState({
   name: '',
   email: '',
   company: '',
   mobileNumber: '' ,
   projectDescription: '',
   file :null,
 });


//  error

const [errors, setErrors] = useState ({
  name: '',
  email: '',
  company: '',
  mobileNumber:'',
})

//  file handle

  const handleFileChange = (e) =>{
    // console.log(e)
    const selectedFile = e.target.files[0];
    setFormDatagetintotuch({ ...formDatagetintotuch, file: selectedFile })
  }



// handle change
   const handleChange = (e) =>{
   setFormDatagetintotuch({...formDatagetintotuch, [e.target.name]:e.target.value})
  };


  const handleChanges = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 10);

    setFormDatagetintotuch({ ...formDatagetintotuch, [e.target.name]: numericValue });
    
  };



  // Handle form submission

  const handleSubmit = (e) =>{
     e.preventDefault();

    const postData = new FormData();
    postData.append('name', formDatagetintotuch.name);
    postData.append('email', formDatagetintotuch.email);
    postData.append('company', formDatagetintotuch.company);
    postData.append('mobileNumber', formDatagetintotuch.mobileNumber);
    postData.append('file', formDatagetintotuch.file);
    postData.append('projectDescription', formDatagetintotuch.projectDescription);


    // Send the POST request using Axios


     axios
       .post('http://192.168.29.229:3003/api/product', postData)
       .then((response)=>{
         console.log(response.data)
       }).catch((err)=>{
        
         console.log(err);
       })
        
// validation


   
   
    let validationErrors = {};
    let isValid = true;

    if (formDatagetintotuch.name.trim() === '') {
      validationErrors.name = 'Name is required.';
      isValid = false;
    }

    if (formDatagetintotuch.email.trim() === '') {
      validationErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formDatagetintotuch.email)) {
      validationErrors.email = 'Email is invalid.';
      isValid = false;
    }
    // if (formDatagetintotuch.mobileNumber.trim() === '') {
    //   validationErrors.mobileNumber = 'Name is required.';
    //   isValid = false;
    // }

    
   
    //  if (formDatagetintotuch.mobileNumber.trim() === '') {
    //    validationErrors.mobileNumber = 'Number is required.';

    //  } else if (/^[0-9\b]+$/.test(formDatagetintotuch.mobileNumber)){
    //   validationErrors.mobileNumber = 'Number is invalid.';
    //   isValid = false;
    //  }
    //  else if (formDatagetintotuch["mobileNumber"].length !== 10)

    //  validationErrors.mobileNumber = "*Please enter valid mobile number.";

    


    if (formDatagetintotuch.company.trim() === '') {
      validationErrors.company = 'company Name is required.';
      isValid = false;
    }
    // if (formDatagetintotuch.mobileNumber.trim() === '') {
    //   validationErrors.mobileNumber = 'mobileNumber is required.';
    //   isValid = false;
    // }
    setErrors(validationErrors);
      };

 

  return (
    <>
     <Helmet >
        <title>Get in touch with Elicit Digital | Contact us now </title>
        <meta name="title" content="Get in touch with Elicit Digital | Contact us now " />
        <meta name="keywords" content=" Contact us now" />
        <meta name="description" content="Reach out to us at Elicit Digital through our Contact Us page. Whether you have questions, feedback, or collaboration inquiries, we're here to help. Let's connect!" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Get in touch with Elicit Digital | Contact us now " />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Reach out to us at Elicit Digital through our Contact Us page. Whether you have questions, feedback, or collaboration inquiries, we're here to help. Let's connect!" />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Get in touch with Elicit Digital | Contact us now " />
        <meta name="twitter:description" content="Reach out to us at Elicit Digital through our Contact Us page. Whether you have questions, feedback, or collaboration inquiries, we're here to help. Let's connect!" />
      </Helmet>
      <div className="contact ">
        <div className="u_page_container-lines"></div>
        <section className="u_page_container">
          <div className="row u_container u_container--main first-section">
            <div className="container  top-section ">
              <div class="row"> <div className="col-lg-6 col-sm-12 col-md-12 centered-section">
                <h1>Let's Create You a Great Product!</h1>
                <div className="description-get">
                  Fill out the form below and tell us what you need.
                </div>

                <div className="contact-form" id>
                  <p className="description-get">
                    Send us a message with a brief description of your project.
                    Our expert team will review it and get back to you within one
                    business day with free consultation and to discuss the next
                    steps.
                  </p>
                  <form onSubmit={handleSubmit} class="contact-form container" id="request-form">
                    <div className="contact-form-row">
                      <div className=" col-lg-6 col-sm-6 col-md-6 contact-input-container padded">
                        <input
                          type="text"
                          className="contact_input"
                          placeholder="Full Name"
                          aria-label="mobile"
                          name="name"
                          value={formDatagetintotuch.name}
                          onChange={handleChange}
                         />
                        {errors.name && <span className="required-get">{errors.name}</span>}
                       
                      </div>
                      <div className=" col-lg-6 col-md-6 col-sm-6 contact-input-container">
                        <input
                          type="text"
                          className="contact_input"
                          placeholder="Company"
                          aria-label="email"
                          name="company"
                          value={formDatagetintotuch.company}
                          onChange={handleChange}
                           />
                        {errors.company && <span className="required-get">{errors.company}</span>}
                      
                      </div>
                      <div className=" inputHelper"></div>
                    </div>

                    <div className="contact-form-row">
                      <div className=" col-lg-6 col-md-6 col-sm-6 contact-input-container padded">
                        {/* <input
                          type="number"
                          className="contact_input"
                          placeholder="Mobile"
                          aria-label="mobile"
                          name="mobileNumber"
                          value={formDatagetintotuch.mobileNumber}
                          onChange={handleChange}
                          /> */}

                        <input
                          type="text"
                          className="contact_input"
                          placeholder="Mobile"
                          aria-label="mobile"
                          name="mobileNumber"
                          value={formDatagetintotuch.mobileNumber}
                          onChange={handleChanges}
                        />
                        {errors.mobileNumber && <span className="required-get">{errors.mobileNumber}</span>}
                       
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 contact-input-container">
                        <input
                          type="email"
                          className="contact_input"
                          placeholder="Email"
                          aria-label="email"
                          name="email"
                          value={formDatagetintotuch.email}
                          onChange={handleChange}
                           />
                        {errors.email && <span className="required-get">{errors.email}</span>}
                         
                      </div>
                      <div className="inputHelper"></div>
                    </div>

                    <div className="contact-form-row ">
                      <div className="contact-input-container">
                        <textarea type="text"
                          className="contact-input contact-textarea"
                          placeholder="Project Description"
                          rows="6"
                          name="projectDescription"
                          value={formDatagetintotuch.projectDescription}
                          onChange={handleChange}
                           
                        ></textarea>
                      </div>
                      <div className="inputHelper"></div>
                    </div>

                    <div className="contact-form-row">
                      <div className="contact-input-container padded">
                        <button className="primary-button" type="submit">
                          Submit
                        </button>
                      </div>
                      <div className="contact-input-container">
                        <label className="primary-button transparent">
                          <img
                            className="button-icon"
                            alt="file_icon"
                            src={file_icon}
                          />
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            onChange={(e) => handleFileChange(e)}
                          ></input>
                          <span>Attach file</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
                <div className="col-lg-6 col-md-12 col-sm-12 carrier-page-img ">
                  <img src={CareerPage} alt="" />
                </div></div>
            </div>
          </div>
        </section>
        <section className="container pt-4">
          <h2 className="our_location">Our Location</h2>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact_second_section">
                <div className="description-get">Just tell us what communication channel you prefer, and we'll contact you back using it. We strive to make our clients comfortable from the first moments of our communication.</div>
                <div className="description_social">Choose a chat or a messenger:</div>
                <div className="social-links-container-get">
                  <a href="https://web.skype.com/" target="/_blank" className="social-link">
                    <img src={skype} alt="skype" />
                  </a>
                  <a href="https://www.facebook.com/ElicitInfotech" target="/_blank" className="social-link">
                    <img src={messanger} alt="messanger" />
                  </a>
                  <a href="https://www.instagram.com/elicit.digital/" target="/_blank" className="social-link">
                    <img src={instagram} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="locations-section2">
                <div className='location_side'>
                  <div className='contact_list_side'>
                    <div className='item_side'> <a href="mailto:sales@elicit.digital" title="sales@elicit.digital">
                      <div className="d-flex">
                        <div className="footer-location">
                          <FiMail />
                        </div>
                        <div>
                          <p>sales@elicit.digital</p>
                        </div>
                      </div>
                    </a></div>
                    <div className='item_side'><div className="d-flex">
                      <div className="footer-location">
                        <ImLocation />
                      </div>
                      <p>
                        Office Plot No. 345, 346, 3rd Floor Scheme 54 PU4, Indore, Madhya Pradesh 452010
                      </p>
                    </div></div>
                    <div className='item_side'> <a href="tel:+91 9111555876" title="+91 9111555876">
                      <div className="d-flex">
                        <div className="footer-location">
                          <IoMdCall />
                        </div>
                        <div>
                          <p>+91 9111555876</p>
                        </div>
                      </div>
                    </a></div>
                  </div>
                </div></div>
            </div>
          </div>
        </section>

      </div>
    </>
  );
};
export default GetTouch;
