import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'react-awesome-slider/dist/styles.css';
// import Ios from "../../assets/img/iOs.jpg";
import bak from "../../assets/img/shape1-min.png";
import office1 from "../../assets/img/testimonial1.png";
import office2 from "../../assets/img/testimonial2.png";
// import office3 from "../../assets/img/testimonial3.png";
import "../Services/services.css"
const ServiceSlider = () => {
    return (
        <Carousel variant="dark">
            <Carousel.Item interval={2000}>
                <div className='row' >
                    <div className='col-lg-6'>
                        <div className='backgornd-img-set'>
                            <div className='img-div frent-screen'> <img
                                className=" "
                                src={office1} alt="img" /></div>
                            <div className='img-div back-background'> <img
                                className=" "
                                src={bak} alt="img" /></div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <h4><strong><span style={{ color: "rgb(14 101 189)" }}>The Bulletproof </span> Performance Philosophy</strong></h4>
                        <p>Helping athletes improve their game is what makes us feel like champions. Bulletproof Performance is more than your average training program. We offer a world class, 360° approach to get you playing at your best.</p>
                        <p className="title-name" style={{ fontsize: "16px" }}><span><strong>Bulletproof</strong>, Volleyball is Our Passion</span></p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='backgornd-img-set'>
                            <div className='img-div frent-screen'> <img
                                className=" "
                                src={office2} alt="img" /></div>
                            <div className='img-div back-background'> <img
                                className=" "
                                src={bak} alt="img" /></div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <h4><strong><span style={{ color: "rgb(14 101 189)" }}>Bob Green</span> My primary focus over</strong></h4>
                        <p>the last 20 years has been enabling people to lead themselves and others. I started to learn about self-leadership and the leadership of others early in life. I had a single mother which meant I had to take care of myself while she was working two jobs to make ends meet. From a leadership of others perspective, I had my first leadership role before graduating high-school</p>
                        <p className="title-name" style={{ fontsize: "16px" }}><span><strong>Bob Green</strong>, What I Learned</span></p>
                    </div>
                </div>
            </Carousel.Item>
            {/* <Carousel.Item interval={2000}>
                <div className='row'>
                <div className='col-lg-6'> 
                <div className='backgornd-img-set'>
                    <div className='img-div frent-screen'> <img
                            className=" "
                            src={office3} alt="img" /></div>
                               <div className='img-div back-background'> <img
                            className=" "
                            src={bak} alt="img" /></div>
                            </div>
                            </div>
                    <div className='col-lg-6'>
                    <h4><strong><span style={{color:"rgb(14 101 189)"}}>Dealium </span> will directly connect small businesses</strong></h4>
                        <p>with new customers, through referrals from their existing customers, in exchange for referral rewards such as discounts on first time and future purchases.</p>
                            <p className="title-name" style={{fontsize: "16px"}}><span><strong>Dealium</strong>, Everything Sorted, Everything Perfect</span></p>
                    </div>
                </div>
            </Carousel.Item> */}
        </Carousel>
    )
}
export default ServiceSlider