import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function toggleTabbtn(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    className="row service_tab"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Our Process" {...toggleTabbtn(0)} />
                    <Tab label="experience" {...toggleTabbtn(1)} />
                    <Tab label="benefits" {...toggleTabbtn(2)} />


                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <div id="content-1">
                    <div id="content-1">
                        <p>
                            A dedicated, professional project manager is assigned to your
                            project, a person highly skilled in Web development, management,
                            communication and project management. Equally important, your project manager will have adequate time to manage your project carefully.
                        </p>
                        <p>
                            We won’t rest until we fully understand your business and your
                            users. We'll provide you with a winning website strategy that is
                            all about bringing the two sides together. That's why we begin
                            every project with an onsite workshop to transfer knowledge and
                            build a consensus.
                        </p>
                    </div>

                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div id="content-2">
                    <div id="content-2">
                        <p>
                        Equipped with extensive research and a strategic mindset, our team dives into the captivating world of web designing. With unrivaled expertise and a commitment to excellence, we embark on crafting extraordinary experiences that not only captivate but also engage and delight customers. Our UX design approach is nothing short of meticulous - each aspect carefully prototyped, refined through multiple iterations, and thoughtfully tested with real users. Through this rigorous process, we ensure every detail remains user-centric, aligning perfectly with their needs and desires. The project stays firmly on track as we weave our way towards achieving objectives that go beyond expectations time after time.
                        </p>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div id="content-3">
                    <div id="content-3">
                        <p>
                        When it comes to the success of your website, our goal is do-or-die. It is imperative to craft a detailed, research-based strategy to create a user-centric user experience. 
                        </p>
                        <p>
                        Our goal is to gain a complete understanding of both your business and your users. In order to transfer knowledge and build consensus, we start every project with an onsite workshop. We provide you with a winning website strategy that brings the two sides together.
                        </p>
                    </div>
                </div>
            </TabPanel>
        </Box>
    );

}
