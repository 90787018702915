import React from 'react'
import "../About.css"
import Carousel from 'react-bootstrap/Carousel';
import 'react-awesome-slider/dist/styles.css';
import office1 from '../../../assets/img/office1cc.png'
import office2 from '../../../assets/img/office2aa.png'
import office3 from '../../../assets/img/office3bb.png'
import office4 from '../../../assets/img/office4dd.png'


 const SmallSlider = () => {
    return (

  <Carousel variant="dark">

  <Carousel.Item interval={3000}>
    <div className='row' ></div>
    <img
      className="d-block w-80"
      src={office1} alt="img"/>
  </Carousel.Item>
  
  <Carousel.Item interval={3000}>
    <img
      className="d-block w-80"
      src={office2} alt="img"/>
  </Carousel.Item>
  <Carousel.Item interval={3000}>
    <img
      className="d-block w-80"
      src={office3} alt="img"/>
  </Carousel.Item>
  <Carousel.Item interval={3000}>
    <img
      className="d-block w-80"
      src={office4} alt="img"/>
  </Carousel.Item>
</Carousel>

 ) }
export default SmallSlider