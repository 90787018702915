import React from "react";
import "./accordion.css";
import { Accordion } from "react-bootstrap";

const Accordions = () => {
  return (
    <div className="accordion_container container">
      <h2 className="accordion_head">Teams & Open Roles</h2>
      <Accordion>
        <div className="row">
          <div className="col-lg-6 col-md-6 acordion_row">
            <Accordion.Item className="Accordion.Item" eventKey="1">
              <Accordion.Header>PHP Developer</Accordion.Header>
              <Accordion.Body>
              <ul>
  <li>Proven experience as a PHP developer in a competitive environment</li>
  <li>Strong knowledge of PHP coding and web-based applications</li>
<li>Good understanding of website integration and software creation</li>
<li>Excellent verbal and written communication skills</li>
<li>Able to follow clear instructions according to client demands</li>
<li>Strong analytical and problem-solving skills</li>
<li>Creative ability to produce effective solutions to client problems.</li>
<li>Good team player that is enthusiastic about delivering results</li>
<li>Able to work seated in front of a computer screen for long hours at a time</li>

</ul>
<ul className="emailhr"><li>

  <a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                      <div>
                      <b>Email id : hr@elicit.digital</b>
                    </div>
                  </a></li></ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>React Native</Accordion.Header>
              <Accordion.Body>
<ul>
  <li>Ability to build apps and websites that are extremely sensitive.</li>
  <li>Bug-finding and bug-fixing abilities.</li>
<li>By eliminating the restriction, you can increase the efficiency of a close-to-native program.</li>
<li>Ability to retain easily understandable code.</li>
<li>Create automation checks to make it easier to test the software.</li>
<li>The opportunity to use native APIs for tight integration through all frameworks.</li>
<li>The opportunity to turn a React Web App into a React Native App entirely.</li>
<li>React native is being used to create new user-facing functionality.</li>
<li>Creating interchangeable front-end modules and repositories for potential use</li>
<li>Creating high-quality programming from prototypes and wireframes</li>
<li>Elements are optimized for optimal efficiency on a wide range of web-capable platforms and browsers.</li>
</ul>
<ul className="emailhr"><li>

  <a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                      <div>
                      <b>Email id : hr@elicit.digital</b>
                    </div>
                  </a></li></ul>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>WordPress Developer</Accordion.Header>
              <Accordion.Body>
              <ul>
  <li>4 to 9 years overall work experience</li>
  <li>3+ or more years experience in WordPress development</li>
<li>Strong in PHP development</li>
<li>Experience in popular WordPress plugins</li>
<li>Good communication skills</li>
<li>Nice to have: Worked with JS frameworks (React.js, etc.)</li>

</ul>
<ul className="emailhr"><li>

  <a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                      <div>
                      <b>Email id : hr@elicit.digital</b>
                    </div>
                  </a></li></ul>
              </Accordion.Body>
            </Accordion.Item>
       
          </div>

          <div className="col-lg-6 col-md-6  acordion_row">
            {/* <Accordion.Item eventKey="4">
              <Accordion.Header>LAMP ( Full Stack )</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>NODE Developer</Accordion.Header>
              <Accordion.Body>
              <ul>
    <li>Design and implementation of low-latency, high-availability, and performant applications</li>
    <li>Implementation of security and data protection</li>
    <li>Understanding of performance testing and optimization techniques</li>
    <li>Create Unit and Integration tests to ensure the quality of code</li>
    <li>Be responsive to change requests and feature requests</li>
    <li>Write code that is cross-platform and cross-device compatible</li>
   </ul>

<ul className="emailhr"><li><b>Email id : hr@elicit.digital</b></li></ul>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="6">
              <Accordion.Header>MEAN ( Full Stack )</Accordion.Header>
              <Accordion.Body>
              <ul>
    <li>Advanced conceptual understanding of at least one Programming Language</li>
    <li>Advanced conceptual understanding of one database and one Operating System</li>
    <li>Understanding of Software Engineering with practice in at least one project</li>
    <li>Ability to contribute in medium to complex tasks independently</li>
    <li>Exposure to Design Principles and ability to understand Design Specifications independently</li>
    <li>Ability to run Test Cases and scenarios as per the plan</li>
    <li>Ability to accept and respond to production issues and coordinate with stake holders</li>
    <li>Good understanding of SDLC</li>
    <li>Analytical abilities </li>
    <li>Logical thinking</li>
    <li>Awareness of latest technologies and trends</li>

   </ul>
   Technical and Professional Requirements:
<ul><li>
• Primary skills:UI & Markup Languages->MEAN Stack</li></ul>
<ul className="emailhr"><li>

  <a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                      <div>
                      <b>Email id : hr@elicit.digital</b>
                    </div>
                  </a></li></ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Business Development Executive ( BDE )
              </Accordion.Header>
              <Accordion.Body>
              <ul>
    <li>Actively seek out new Sales Opportunities.</li>
    <li>Experience generating leads through Linkedin, Linkedin Sales Navigator and similar portals.</li>
    <li>Building out and maintaining a strong pipeline of leads and potential new business.</li>
    <li>Conduct market research to identify selling possibilities.</li>
    <li>Develop and manage efficient networks for Business Development.</li>
    <li>Maintain knowledge of all product and service offerings of the company.</li>
    <li>Write new business proposals.</li>
    <li>Keep prospective client database/CRM updated.</li>
   </ul>

   <ul className="emailhr"><li>

<a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                    <div>
                    <b>Email id : hr@elicit.digital</b>
                  </div>
                </a></li></ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>MERN ( Full Stack )</Accordion.Header>
              <Accordion.Body>
   <ul>
    <li>Design and implementation of low-latency, high-availability, and performant applications</li>
    <li>Implementation of security and data protection</li>
    <li>Understanding of performance testing and optimization techniques</li>
    <li>Create Unit and Integration tests to ensure the quality of code</li>
    <li>Be responsive to change requests and feature requests</li>
    <li>Write code that is cross-platform and cross-device compatible</li>
   </ul>
   <ul className="emailhr"><li>

<a href="mailto:hr@elicit.digital" title="sales@elicit.digital">
                    <div>
                    <b>Email id : hr@elicit.digital</b>
                  </div>
                </a></li></ul>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
export default Accordions;
