import React, { Component } from "react";
import "./App.css";
import "./Components/About/About.css";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Portfolio from "./Components/Portfolio/Portfolio";
import LifeAtElicit from "./Components/LifeAtElicit/lifeAtElicit";
import OurTeam from "./Components/About/Our-Team/OurTeam";
import Header from "./Components/Header/Header";
import GetTouch from "./Components/Contact/Get-Touch/GetTouch";
import AndroidDevelopment from "./Components/Services/AndroidDevelopment";
import WebDevelopment from "./Components/Services/WebDevelopment";
import Career from "./Components/Career/Career";
import GoTo from "./Components/GoTop/Goto";
import IosDevelopment from "./Components/Services/IosDevelopment";
import HybridDevelopment from "./Components/Services/HybridDevelopment";
import WearableDevelopment from "./Components/Services/WearableDevelopment";
import UiUxDevelopment from "./Components/Services/UiUxDevelopment";
import IOTDevelpment from "./Components/Services/IOTDevelpment";
import QaTesting from "./Components/Services/QaTesting";
import BeaconDevelopment from "./Components/Services/BeaconDevelopment";
import blog from "./Components/Blog/Blog";
import integratChatGpt from "./Components/Blog/integratChatGpt";
import artificialIntelligence from "./Components/Blog/artificialIntelligence";
import agileMethodologies from "./Components/Blog/agileMethodologies";
import genertorAitools from "./Components/Blog/genertorAitools";
import Effectivestrategies from "./Components/Blog/EffectiveStrategies";
import Differentindustries from "./Components/Blog/differentindustries";
import Prosandcons from "./Components/Blog/prosandcons";
import Emerging from "./Components/Blog/Emerging";
import Internetofthings from "./Components/Blog/Internetofthings";
import Emergingtechnologies from "./Components/Blog/Emergingtechnologies";
import NotFound from "./Components/404";
import TermAndConditions from "./Components/TermsAndCondition/index";
import PrivacyPolicy from "./Components/PrivacyPolicy/index";
import GitexLandingPage from "./Components/GitexLandingPage/index";
import VCard from "./Components/VCard/index";
import component from "react-hyper-modal";
import GoHighLevel from "./Components/GoHighLevel";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function App({location}) {
  const scrolltop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  scrolltop();
   const isVCardRoute = location.pathname === "/vcard";
  return (
    <div className="App">
      {!isVCardRoute && <Header />}
      <div>
        <Switch>
          <Route exact path="/vcard" component={VCard} />
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/elicit-x-gitex-2024"
            component={GitexLandingPage}
          />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/life-at-elicit" component={LifeAtElicit} />
          <Route exact path="/ourteam" component={OurTeam} />
          <Route exact path="/get-in-touch" component={GetTouch} />

          <Route
            exact
            path="/android-app-development-services"
            component={AndroidDevelopment}
          />
          <Route
            exact
            path="/web-development-services"
            component={WebDevelopment}
          />
          <Route exact path="/career" component={Career} />

          <Route
            exact
            path="/ios-app-development-services"
            component={IosDevelopment}
          />

          <Route
            exact
            path="/hybrid-app-development-services"
            component={HybridDevelopment}
          />

          <Route
            exact
            path="/wearable-app-development-services"
            component={WearableDevelopment}
          />
          <Route
            exact
            path="/ui-ux-design-services"
            component={UiUxDevelopment}
          />

          <Route
            exact
            path="/iot-software-development-services"
            component={IOTDevelpment}
          />
          <Route exact path="/qa-testing" component={QaTesting} />
          <Route
            exact
            path="/beacon-development-services"
            component={BeaconDevelopment}
          />
          <Route exact path="/blog" component={blog} />
          <Route
            exact
            path="/how-to-integrate-chat-gpt"
            component={integratChatGpt}
          />
          <Route
            exact
            path="/effective-strategies"
            component={Effectivestrategies}
          />
          <Route exact path="/genertorAitools" component={genertorAitools} />
          <Route
            exact
            path="/differentindustries"
            component={Differentindustries}
          />
          <Route exact path="/prosandcons" component={Prosandcons} />
          <Route exact path="/Emerging" component={Emerging} />
          <Route exact path="/Internetofthings" component={Internetofthings} />
          <Route
            exact
            path="/Emergingtechnologies"
            component={Emergingtechnologies}
          />
          <Route
            exact
            path="/artificial-intelligence-and-machine-learning"
            component={artificialIntelligence}
          />
          <Route
            exact
            path="/adopting-agile-methodologies-in-software-development"
            component={agileMethodologies}
          />
          <Route path="/404" component={NotFound} />
          <Route path="/terms-and-conditions" component={TermAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />

          <Route path="/gohighlevel" component={GoHighLevel} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
      <GoTo />
      {!isVCardRoute && <Footer />}
    </div>
  );
}
export default withRouter(App);
