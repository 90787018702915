import { Link } from "@material-ui/core";
import React from "react";
const TermAndConditions = () => {
  return (
    <div className="privacy">
      <section className="privacy-bg">
        <div className="topbgimage-policy">
          <h2>Web And Mobile Application Development Company</h2>
        </div>
        <div className="free-audti mt-4">
          <a class="get_btn py-3 px-3" href="/get-in-touch">
            Get in Touch
          </a>
        </div>
      </section>
      <div className="container mt-5">
        <div class="yellowBoxShow"></div>
        <div class="blueboxanimation"></div>
        <div class="leftBorder"></div>
        <div className="privacy-page mt-5 mb-4">
          <div>
            <ul className="d-flex breadcrumb-privacy">
              <li>
                <Link href="/">HOME</Link>
              </li>
              <li>
                <Link href="/terms-and-conditions">TERMS AND CONDITIONS</Link>
              </li>
            </ul>
          </div>
          <h1>TERMS AND CONDITIONS</h1>
          <p>
            Elicit reserves the right to change the Terms and Conditions at any
            time, for any reason, by using this website.
          </p>
          <div>
            <h3>Website Usage Terms And Conditions</h3>
            <p>
              By accepting these Terms and Conditions, Elicit provides you with
              a non-transferable, non-exclusive license to utilize the website
              and content of emails received from Elicit. The
              copyright-protected content within both mediums is exclusively
              owned by Elicit. Any unauthorized copying, reproducing, modifying,
              or creating derivative works based on the information provided in
              connection with the website or emails is strictly prohibited
              unless in accordance with these Terms and Conditions.
            </p>
            <p>
              The website and email content must not be used in a way that
              opposes Elicit's interests, harms its reputation, violates
              anyone's rights, engages in illegal activities or compromises any
              computer systems. This includes actions like spamming, behaving
              contrary to established internet etiquette, accessing or sharing
              objectionable material, engaging in defamation or offensive
              behavior, spreading false information or using it for hacking
              purposes.
            </p>
          </div>
          <div>
            <h3>Your Obligations And Conduct</h3>
            <h4 className="d-flex">
              <span className="mr-1">
                <b>1.1</b>
              </span>
              By using the Website, you acknowledge and agree to the following
              terms:
            </h4>
            <ul className="pl-5 my-3">
              <li className="d-flex">
                <span className="mr-4">a.</span>
                You will provide accurate and complete information about
                yourself when prompted by a registration form on the Website;
              </li>
              <li className="d-flex">
                <span className="mr-4">b.</span>
                It is your responsibility to keep this information up to date;
                and
              </li>
              <li className="d-flex">
                <span className="mr-4">c.</span>
                You accept any risks associated with unauthorized access to your
                information. Moreover, you are solely responsible for ensuring
                adequate protection and backup of any data or equipment used in
                connection with the Website.
              </li>
            </ul>
          </div>
          <div>
            <h4 className="d-flex">
              <span className="mr-1">
                <b>1.2</b>
              </span>
              All Content that you upload, post, or otherwise transmit via the
              Website is entirely your responsibility. You agree not to upload,
              post or otherwise transmit through the Website Content that:
            </h4>
            <ul className="pl-5 my-3">
              <li className="d-flex">
                <span className="mr-4">a.</span>
                Is inaccurate, harmful, obscene, pornographic, defamatory,
                racist, violent, offensive, harassing, or otherwise
                objectionable to Elicit or other users of the Website.
              </li>
              <li className="d-flex">
                <span className="mr-4">b.</span>
                The disclosure of personal information without authorization
              </li>
              <li className="d-flex">
                <span className="mr-4">c.</span>
                Violates or infringes anyone's intellectual property rights
              </li>
              <li className="d-flex">
                <span className="mr-4">d.</span>
                Inhibits, destroys, or restricts the functionality of any
                computer software, hardware, or telecommunications equipment by
                containing software viruses or other computer codes, files or
                programs. If Content violates these Terms or contains
                third-party advertisements, Elicit reserves the right to edit or
                remove it.
              </li>
            </ul>
          </div>
          <div>
            <h4 className="d-flex">
              <span className="mr-1">
                <b>1.3</b>
              </span>
              By using the Website, you agree not to:
            </h4>
            <ul className="pl-5 my-3">
              <li className="d-flex">
                <span className="mr-4">a.</span> Send spam, bulk or unsolicited
                communications;
              </li>
              <li className="d-flex">
                <span className="mr-4">b.</span> Pretend to be Elicit Software
                or someone else, or spoof Elicit Software’s or someone else’s
                identity;
              </li>
              <li className="d-flex">
                <span className="mr-4">c.</span>
                Forge headers or otherwise manipulate identifiers (including
                URLs) in order to conceal the origin of any Content transmitted
                through the Services;
              </li>
              <li className="d-flex">
                <span className="mr-4">d.</span>Misrepresent your affiliation
                with a person or entity.
              </li>
              <li className="d-flex">
                <span className="mr-4">e.</span> Intentionally interrupt the
                natural conversation on the Website or behave in a way that
                hinders other users from using it smoothly;
              </li>
              <li className="d-flex">
                <span className="mr-4">f.</span> Perform actions against
                fiduciary obligations, any relevant local, state, national or
                global law or regulations with legal weight. These include but
                are not limited to attempting to breach the security of any
                connected account or website, organizing an illegal lottery or
                gambling activity, stalking someone, or making threats of
                violence;{" "}
              </li>
              <li className="d-flex">
                <span className="mr-4">g.</span> Gather or keep personal
                information about other users without their explicit permission.
              </li>
            </ul>
          </div>
          <div>
            <h3>Standard Services Terms</h3>
            <p>
              Elicit will make every effort to complete the services within the
              agreed timeframe specified in any corresponding SOW. It is
              understood that Elicit cannot begin performing the services until
              it receives all necessary content, materials, and information from
              the Customer. Any delays or failures that occur due to actions or
              omissions by the Customer or a violation of this agreement will
              not be attributable to Elicit, absolving them from any liability.
            </p>
          </div>
          <div>
            <h3>Intellectual Property Rights</h3>
            <p>
              All rights, titles, and interests in and to the Deliverables shall
              be owned by the Customer upon receipt of full payment by the
              Consultant.
            </p>
            <p>
              When the Deliverables incorporate Consultant pre-existing
              intellectual property ("Consultant Pre-existing IP"), and such
              Consultant Pre-Existing IP is necessary for the Deliverables to
              function properly, Consultant grants Customer a perpetual,
              non-exclusive, worldwide, transferable, royalty-free license to
              use such Consultant Pre-Existing IP solely in conjunction with the
              Deliverables.
            </p>
          </div>
          <div>
            <h3>Warranties</h3>
            <p>
              All implied, statutory, or other warranties, including without
              limitation, implied warranties of merchantability,
              non-infringement, title, and fitness for a particular purpose, are
              hereby disclaimed by the parties, except as expressly stated in
              this Agreement.
            </p>
          </div>
          <div>
            <h4>
              Get in touch with the experts if you have a project in mind.
            </h4>
            <Link href="/get-in-touch">
              <h4> Metioned contact us form and contact details here</h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermAndConditions;
