import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../../assets/img/logo-img.jpg";



const OurTeam = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  });

  return (
    <>
      <div className="our-team-bg-img">

      </div>
      <section className="our-team-bg">

        <div className="container">
          <h2 className="text-center">
            {" "}
            <span className="Oteam-span">Our Specialist </span> Successful Team{" "}
          </h2>
          <p className="text-center">
            Through careful selection, we’ve curated a group of experts that{" "}
            <br /> are not only knowledgeable but also very reliable.{" "}
          </p>
          <div className=" justify-content-center row">
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <br />
              <div>
                <h4>Ankita</h4>
                <h5>Co-Founder</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Harsh</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Nitin</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Abhay</h4>
                <h5>Senior UI Developer</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <br />
              <div>
                <h4>Hemant</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Sonu</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Aishwarya</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Vishnu</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Vikram</h4>
                <h5>Senior Project Manager</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Srishti</h4>
                <h5>Project Manager</h5>
              </div>
            </div>



            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Anil</h4>
                <h5>UI Developer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Amit</h4>
                <h5>Senior Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Babloo</h4>
                <h5>Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6  col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Pooja</h4>
                <h5>Sales Executive</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Chaitanya</h4>
                <h5>Digital Marketing Executive</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Hiresh</h4>
                <h5>Software Engineer</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Neha</h4>
                <h5>Software Engineer</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Naman</h4>
                <h5>Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Prakhar</h4>
                <h5>BDE</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Ankit</h4>
                <h5>BDE</h5>
              </div>
            </div>
            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Ruchi</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Shaheen</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Anjana</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Pankaj</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Ankit</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Naman</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Rahul</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Devraj</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Aakansha</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>


            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Shubham</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Yogesh</h4>
                <h5>Associate Software Engineer</h5>
              </div>
            </div>

            <div className="team__image-wrapper col-lg-3 col-md-4 col-sm-6 col-xs-6">
              <Link to="#" className="team__image">
                <img
                  src={LogoIcon}
                  alt=""
                />{" "}
              </Link>
              <div>
                <h4>Vivek</h4>
                <h5>Sales Executive</h5>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeam;
