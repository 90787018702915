import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'react-awesome-slider/dist/styles.css';
// import Ios from "../../assets/img/iOs.jpg";
// import bak from "../../assets/img/shape1-min.png";
// import office1 from "../../assets/img/testimonial1.png";
// import office2 from "../../assets/img/testimonial2.png";
// import office3 from "../../assets/img/testimonial3.png";
import ImgElicit from "../../assets/img/img-elicit1.jpg";
import ImgElicitProgram from "../../assets/img/elicit-program1.jpg";
import ImgElicitLearning from "../../assets/img/elicit-learning1.JPG";
import ImgElicitActivities from "../../assets/img/elicit-fun-activities1.jpg";




import "./ElicitLifeslider.css"


const ServiceSlider = () => {
    return (

        <Carousel variant="dark">

        <Carousel.Item interval={2000}>
            <div className='row' >
                <div className='col-lg-5'>
                    <div className=''>
                        <div className='img-div frent-screen'>
                            <img className=" " src={ImgElicit} alt="mobile app development services" />
                        </div>
                    </div>
                </div>
                <div className='col-lg-7'>
                    <h4><strong>Life @Elicit</strong></h4>
                    <p>Life@Elicit, comes with lot of work but also with lot of celebrations that comes when all the Elicitians come together at different festivals, events happing in the organisation.</p>
                    <p>With a stong core value of Strewardship, where each individual believes as if Elicit belongs to them brings more owneship in celebrating it together.</p>
                    <p>At Elicit, We believe in encoraging team members, to learn, adapt, implement and manage a stong professional and personal life balance by giving them freedom to work in a creative enviornment. A great place to work for Elicit doesnot comes with it's quality toward its deliverables for the clients but also the way it takes care of all of the Elicitians.</p>

                </div>

            </div>

        </Carousel.Item>

        <Carousel.Item interval={2000}>
            <div className='row'>
                <div className='col-lg-5'>
                    <div className=''>
                        <div className='img-div frent-screen'> <img
                            className=" "
                            src={ImgElicitProgram} alt="mobile app development services" /></div>
                    </div>
                </div>
                <div className='col-lg-7'>
                    <h4><strong>Celeberation @Elicit</strong></h4>
                    <p>We at Elicit always looks forward to a way where team can come up and celeberate together, Specially after pandemics where everyone had just those vitual celeberations either on zoom or google meet some real celeberation makes us feel life and it's meaning.</p>
                    <p>Whether it is ganpati, navratri, christmas or a normal B'day Celeberation you will see out team in the best attire and perfect mood to have a blast, some glimpse included below definitely gives an idea of how Elicit is celeberating all the festivals with heart and lot of enthusiasm.</p>


                </div>

            </div>

        </Carousel.Item>

        <Carousel.Item interval={2000}>

            <div className='row'>
                <div className='col-lg-5'>
                    <div className=''>
                        <div className='img-div frent-screen'> <img
                            className=" "
                            src={ImgElicitLearning} alt="mobile app development services" /></div>
                    </div>
                </div>
                <div className='col-lg-7'>
                    <h4><strong>Learning @Elicit</strong></h4>
                   <p><strong>Expand your edge</strong> <br/> Learning is in our DNA. We commit to offering our people opportunities to acquire new skills and strengthen their readiness to advance into new roles.</p>
                   <p><strong>24/7 learning</strong> <br/> The world is at your fingertips with unlimited access to leading mobile-friendly online training curated by Elicit subject matter experts to help you advance at every level of your career.</p>
                   

                </div>

            </div>
        </Carousel.Item>

        <Carousel.Item interval={2000}>

            <div className='row'>
                <div className='col-lg-5'>
                    <div className=''>
                        <div className='img-div frent-screen'> <img
                            className=" "
                            src={ImgElicitActivities} alt="mobile app development services" /></div>
                    </div>
                </div>
                <div className='col-lg-7'>
                    <h4><strong>Fun Activities @Elicit</strong></h4>
                    <p>"Good Times and Crazy Teammates makes the best memory, and we at Elicit always believes in Best"</p>
                    <p>It is always important to have fun at your workplace and Elicitians knows it pretty well as they don't miss out any oppurtunity to have different fun activities which doesnot just ligten the mood but also motivates team to deliver extra keeping the happiness as the priority.</p>
                    <p>We always seek chances to travel together may be a normal team day out, some hiking or trekking or a night stay at farm house. Different team building activities, fun events and reward and recognition is also an essential part of our core values.</p>
                   

                </div>

            </div>
        </Carousel.Item>


    </Carousel>
    )
}
export default ServiceSlider