import React from "react";
import {Helmet} from "react-helmet";
import "./services.css";
import { Link } from "react-router-dom";
import icon from "../../assets/img/icon.png";
import phone from "../../assets/img/phone icon.png";
import rocket from "../../assets/img/rocket-launcher.jpg";
// import Ser from "../../assets/img/ser-pg.png";
import Ser from "../../assets/img/WHAT-WE0-OFFER.png";
import Tab from "../../Components/Slideshow UI/tab";
import ServiceSlider from "../../Components/Services/servesslider";
// import Jquery from "../../assets/img/jquery.jpg";
// import Html from "../../assets/img/html.jpg";
// import Wardpress from "../../assets/img/wardpress.jpg";
// import { useEffect } from "react";
import UiUxDesign from "../../assets/img/ui-ux-design.png";
import BeaconDevelopment from "../../assets/img/Beacon-Development.png";
import IOTDevelopment from "../../assets/img/IOT-Development.png";
import WebDevelopment from "../../assets/img/Web-Development.png";
import WearableDevelopment from "../../assets/img/icon9.png";
import HybridMobile from "../../assets/img/HybridMobile.png";
import AndroidDevelopments from "../../assets/img/Android-Developments.png";
import IsoDevelopment from "../../assets/img/iso-development.png";
const Services = () => {
  return (
    <div>
      <Helmet>
        <title>Web Design and Development Services | Try it Free </title>
        <meta name="title" content="Web Design and Development Services | Try it Free" />
        <meta name="keywords" content="Web Design and Development Services, web & mobile app design and development" />
        <meta name="description" content="Let's explore how our innovative digital solutions can grow your business. We provide a comprehensive range of services, including web & mobile app design and development." />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Web Design and Development Services | Try it Free" />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Let's explore how our innovative digital solutions can grow your business. We provide a comprehensive range of services, including web & mobile app design and development." />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Web Design and Development Services | Try it Free" />
        <meta name="twitter:description" content="Let's explore how our innovative digital solutions can grow your business. We provide a comprehensive range of services, including web & mobile app design and development." />
      </Helmet>
      <section className="bg_img">
        <div className="container">
          <div className="row service-container-head-row">
            <div className="col-lg-6 service_col_title" >
              <h3 className="dbl_subtitle"> WHAT WE OFFER</h3>
              <h3 className="dbl__title-wrapper">
                <span>Proven Website Development Experience</span>
              </h3>
              <div className="services-custom-text-editor">
                <p>
                <strong>Elicit</strong>  is a leading provider of web design, SEO, ecommerce, website conversion, and Internet marketing services.
                </p>
              </div>
            </div>
            <div className="col-lg-6 computer-img" >
              <img className="img_computer" src={Ser} alt="website designing company" />
            </div>
          </div>
        </div>
      </section>
      <div className="container tabpage-service">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row service-icon-row ">
              <div className="col align-self-start icon_first">
                <div>
                  <img className="service-img" src={icon} alt="website designing company" />
                </div>
                <span className="wgl-infobox_title"> Web Development</span>
                <p className="icon_text-para">
                  Consider. Shape. <strong>Influence</strong>. Shine.
                </p>
              </div>
              <div className="col align-self-center icon_second">
                <div>
                  <img className="service-img" src={phone} alt="website designing company" />
                </div>
                <span className="wgl-infobox_title">App Development</span>
                <p className="icon_text-para">
                  <strong>Applications</strong> for all your problems{" "}
                </p>
              </div>
              <div className="col align-self-end icon_third">
                <div>
                  <img className="service-img" src={rocket} alt="web development services" />
                </div>
                <span className="wgl-infobox_title">Digital Marketing</span>
                <p className="icon_text-para">
                  All you need is a <strong>Digital Push</strong>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 subtitle-tab" >
            <div className="dbl_subtitle">Focus on Lead Generation</div>
            <h3 className="dbl__title-wrapper">
              <span className="dbl__title">
                A Look at Our Web Development Services
              </span>
            </h3>
            {/* <Toggle /> */}
            <Tab />
          </div>
        </div>
      </div>
      <div className="section-services1 services2">
        <section className="section-services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="row">
                  <div className="col-lg-9">
                    <h2>
                      <span>Package Of</span> Services
                    </h2>
                    <h3>
                      With a blend of technology partner rather than a vendor
                    </h3>
                  </div>
                  <div className="col-lg-3 col-connect" >
                    <h3 >To Discuss a Project</h3>
                    <Link
                      to="/get-in-touch"
                      title="Let's Connect"
                      className="btn"
                    >
                      Let's Connect
                    </Link>
                  </div>
                  <div className='bg-portfolio-con'>
                    <div className="container">
                      <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/android-app-development-services" >
                            <div className=' portfolio-img' >
                              <div className='portfolio-imgs' >
                                <ul className='circle-portfolio'>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <div> <div className="img-icon-for-ser"><img className="port-folio-img" src={AndroidDevelopments} alt="website designing company" /></div>
                                  <h3> Android Development</h3>
                                </div>
                                <div className="port-folio-content">
                                  <h4> Android Development</h4>
                                  <p>
                                    At our company a team of devoted and skilled developers blends their skills with the cutting-edge mobile technology almost at all platforms. </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/ios-app-development-services"
                          >
                            <div className=' portfolio-img'>
                              <div className='portfolio-imgs'>
                                <ul className='circle-portfolio'>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <div>
                                  <div className="img-icon-for-ser">
                                    <img className="port-folio-img" src={IsoDevelopment} alt="website development company" />
                                  </div>
                                  <h3>iOS Development</h3>
                                </div>
                                <div className="port-folio-content">
                                  <h4>iOS Development</h4>
                                  <p>
                                    We have a team of innovative and creative who delivers a quick, full-featured, novel, and secured iOS app at an affordable price.    </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/hybrid-app-development-services"
                          >
                            <div className=' portfolio-img'>
                              <div className='portfolio-imgs'>
                                <ul className='circle-portfolio'>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <div>
                                  <div className="img-icon-for-ser">
                                    <img className="port-folio-img" src={HybridMobile} alt="website development company" />
                                  </div>
                                  <h3> Hybrid App Development</h3></div>
                                <div className="port-folio-content">
                                  <h4> Hybrid App Development</h4>
                                  <p>
                                    Amplify Your Business with Hybrid Mobile Apps </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/wearable-app-development-services"
                          >
                          <div className=' portfolio-img'>
                            <div className='portfolio-imgs'>
                              <ul className='circle-portfolio'>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <div>
                                <div className="img-icon-for-ser">
                                  <img className="port-folio-img" src={WearableDevelopment} alt="ios app developer" />
                                </div>
                                <h3> Wearable Development</h3></div>
                              <div className="port-folio-content">
                                <h4> Wearable Development</h4>
                                <p>
                                  Right from the production of strong backend framework to the reconciliation of APIs welcoming various applications and highlights on a solitary stage,  we specialize in creating a wearable app</p>
                              </div>
                            </div>
                          </div>
                          </Link>
                        </div> <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/web-development-services"
                          >
                            <div className=' portfolio-img'>
                              <div className='portfolio-imgs'>
                                <ul className='circle-portfolio'>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <div>      <div className="img-icon-for-ser">  <img className="port-folio-img" src={WebDevelopment} alt="website developer" />
                                </div><h3> Web Development</h3></div>
                                <div className="port-folio-content">
                                  <h4> Web Development</h4>
                                  <p>
                                    We at Elicit Digital Company have handpicked talent and skilled app developers to accomplish your mobile or web app development project. </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6' >
                          <div className=' portfolio-img'>
                            <div className='portfolio-imgs'>
                              <ul className='circle-portfolio'>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <div>      <div className="img-icon-for-ser">
                                <img className="port-folio-img" src={IOTDevelopment} alt="IOTDevelopment services" />
                              </div>
                                <h3>IOT-Development</h3></div>
                              <div className="port-folio-content">
                                <h4>IOT-Development</h4>
                                <p>
                                  These days, when organizations across enterprises are associating their gadgets and sensors to the web to improve client experience, save energy, support efficiency, and release new income streams.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6' >
                          <div className=' portfolio-img'>
                            <div className='portfolio-imgs'>
                              <ul className='circle-portfolio'>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <div>
                                <div className="img-icon-for-ser">
                                  <img className="port-folio-img" src={BeaconDevelopment} alt="BeaconDevelopment services" />
                                </div>
                                <h3>  Manual Testing</h3>
                              </div>
                              <div className="port-folio-content">
                                <h4>  Manual Testing</h4>
                                <p>
                                  our manual testing services offer increased debugging. Sometimes you can’t find bugs without a fine-tooth comb. Our manual testing is as fine as it gets.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6' >
                          <Link
                            to="/ui-ux-design-services"
                          >
                            <div className=' portfolio-img'>
                              <div className='portfolio-imgs'>
                                <ul className='circle-portfolio'>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <div> <div className="img-icon-for-ser">
                                  <img className="port-folio-img" src={UiUxDesign} alt="uiux" />
                                </div>
                                  <h3>UI/UX designs</h3></div>
                                <div className="port-folio-content">
                                  <h4>UI/UX designs</h4>
                                  <p>
                                    Specialty and plan exquisite and basic wireframes and plans mock-ups for your product that look creative.</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  We partnered with various Brands, SME’s & Startups with a
                  flexible mobile app development service package that suits and
                  fits their requirements. Our experts help you to choose the best
                  combination of technology, team & approach basis on the
                  requirements, time, size, and budget of your project.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div>
        </div>
      </div>
      <div class="slider-services container new-slide"><ServiceSlider /></div>
      <div>
      </div>
    </div>
  );
};
export default Services;
