import React from "react";
import { Accordion } from "react-bootstrap";
import "./accordian.css";

const ServicesAccordions = () => {
    return (

        
        <div className="accordion_container container wearable-app-Accordion">
            <Accordion>
                
                        <Accordion.Item className="Accordion.Item" eventKey="10">
                            <Accordion.Header>Wearable Solution Design</Accordion.Header>
                            <Accordion.Body>
                                <p className="accordian-text">Our team of designers is committed to crafting immersive experiences that transcend all platform sizes through comprehensive alignment with glanceability, context awareness, and simplistic interface designs. We comprehend the individual aspect ratios involved in designing wearables with small screens or irregular shapes versus larger devices such as headphones that allow for more expansive media display options. With our extensive knowledge of this subject matter, we ensure a seamless transition for users adhering to analytical insights that prioritize functionality while minimizing unnecessary touches & swipes across various wearable platforms</p>

                                
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="Accordion.Item" eventKey="11">
                            <Accordion.Header>Wearable Device App Development</Accordion.Header>
                            <Accordion.Body>
                        <p className="accordian-text">As leaders in the field of Wearable Device App Development, we take end-to-end app development for wearable devices to a whole new level. Our expert team works tirelessly to create a robust backend infrastructure that can effortlessly integrate APIs and bring numerous applications and features onto one single platform. Whether it’s smartwatches or fitness trackers, our skilled professionals specialize in designing apps that don't let device limitations get in the way of delivering an exceptional user experience.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                       

                    
                    <Accordion.Item className="Accordion.Item" eventKey="12">
                        <Accordion.Header>Multi-Platform Integration</Accordion.Header>
                        <Accordion.Body>
                        <p className="accordian-text">We understand the importance of Multi-Platform Integration when it comes to wearable application development. Our team specializes in creating APIs and integrations that allow for seamless data transmission between wearables and mobile applications. This means that our clients can rest assured that their users' data is always up-to-date and accurate, without any lag or delays.</p>
                        </Accordion.Body>
                    </Accordion.Item>

                        <Accordion.Item className="Accordion.Item" eventKey="13">
                            <Accordion.Header>Seamless Deployment</Accordion.Header>
                            <Accordion.Body>
                        <p className="accordian-text">Seamless deployment is at the heart of everything our system architects do. Our team specializes in making sure your wearable application runs like a dream on all devices and versions, from the latest models to those that have long been superseded by more cutting-edge technology. We understand that you need your app to work smoothly no matter what, without any hiccups or glitches along the way. To achieve this, we carefully test every element of your app against multiple platforms until we're confident it can stand up to anything and everything that's thrown it’s way.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                   
            </Accordion>
        </div>
    );
};
export default ServicesAccordions;
