import React from "react";
import {Helmet} from "react-helmet";

import "./lifeAtElicit.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import OfficeBuilding from "./img/office-building.jpg";
import ImgElicit1 from "./img/img-elicit1.jpg";
import ImgElicit2 from "./img/img-elicit2.jpg";
import ImgElicit4 from "./img/img-elicit4.jpg";
import ImgElicit5 from "./img/img-elicit5.jpg";
import ImgElicitProgram1 from "./img/elicit-program1.jpg";
import ImgElicitProgram2 from "./img/elicit-program2.jpg";
import ImgElicitProgram3 from "./img/elicit-program3.png";
import ImgElicitProgram4 from "./img/elicit-program4.jpg";
import ImgElicitProgram5 from "./img/elicit-program5.jpg";
import ImgElicitProgram6 from "./img/elicit-program6.jpg";
import ImgElicitLearning1 from "./img/elicit-learning1.JPG";
import ImgElicitLearning2 from "./img/elicit-learning2.JPG";
import ImgElicitLearning3 from "./img/elicit-learning3.jpg";

import ImgElicitFunActivities1 from "./img/elicit-fun-activities1.jpg";
import ImgElicitFunActivities2 from "./img/elicit-fun-activities2.jpg";
import ImgElicitFunActivities3 from "./img/elicit-fun-activities3.jpg";
import ImgElicitFunActivities4 from "./img/elicit-fun-activities4.jpg";
import ImgElicitFunActivities5 from "./img/elicit-fun-activities5.jpeg";
import ImgElicitFunActivities6 from "./img/elicit-fun-activities6.jpeg";



const lifeAtElicit = () => {
  return (
    <>
    <Helmet>
        <title>Life at Elicit Digital | Join Our Team now</title>
        <meta name="title" content="Life at Elicit Digital | Join Our Team now" />
        <meta name="keywords" content="Life at Elicit Digital, Join Our Team now" />
        <meta name="description" content=" Explore our dynamic community and rich culture at Elicit. Come along with us on a journey of creativity, passion, and fulfillment. Let's get started with us!" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Life at Elicit Digital | Join Our Team now" />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content=" Explore our dynamic community and rich culture at Elicit. Come along with us on a journey of creativity, passion, and fulfillment. Let's get started with us!" />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Life at Elicit Digital | Join Our Team now" />
        <meta name="twitter:description" content=" Explore our dynamic community and rich culture at Elicit. Come along with us on a journey of creativity, passion, and fulfillment. Let's get started with us!" />
      </Helmet>
      <div className="lifeAtElicit_bgpage">
        <div className="container">
          <div class="row top-section">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h1>Life At Elicit</h1>
              <p>Life at Elicit is rewarding, fun, and packed with opportunities to learn, take on exciting challenges, and play a part in building a better future through technology. Together, we exchange ideas, share knowledge, and always push ourselves to go further to reimagine what’s possible.</p>
            </div>
          </div>
        </div>
      </div>
      <section className="elicitEventsOne sectionEvents">
        <div className="container">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 order-md-0 order-lg-1 mb-5 mb-lg-0">
              <h2>Life @Elicit</h2>
              <p>Life @Elicit, comes with lot of work but also with lot of celebrations that comes when all the Elicitians come together at different festivals, events happing in the organisation.</p>
              <p>With a stong core value of Strewardship, where each individual believes as if Elicit belongs to them brings more owneship in celebrating it together.</p>
              <p>At Elicit, We believe in encoraging team members, to learn, adapt, implement and manage a stong professional and personal life balance by giving them freedom to work in a creative enviornment. A great place to work for Elicit doesnot comes with it's quality toward its deliverables for the clients but also the way it takes care of all of the Elicitians.</p>
              {/* <p>We deliver on the promise of helping our customers, colleagues, and communities thrive in an ever-changing world.</p> */}

            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12">
                  <ul class="singal-gallery">
                    <li>
                      <img src={ImgElicit1} alt="mobile app development servicesg" />
                    </li>
                  </ul>

                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 p-0">
                  <ul class="image-gallery">
                    <li>
                      <img src={ImgElicit2} alt="mobile app development servicesg" />
                    </li>
                    <li>
                      <img src={ImgElicit5} alt="mobile app development services" />
                    </li>
                    <li>
                      <img src={ImgElicit4} alt="Digital Marketing" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="elicitEventsTwo sectionEvents">
        <div className="container">
          <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-lg-0 mb-2">
              <h2>Celeberation @Elicit</h2>
              <p>We at Elicit always looks forward to a way where team can come up and celeberate together, Specially after pandemics where everyone had just those vitual celeberations either on zoom or google meet some real celeberation makes us feel life and it's meaning.</p>
              <p>Whether it is ganpati, navratri, christmas or a normal B'day Celeberation you will see out team in the best attire and perfect mood to have a blast, some glimpse included below definitely gives an idea of how Elicit is celeberating all the festivals with heart and lot of enthusiasm.</p>
              <ul class="image-gallery galleryTopSpacimg" >
                <li>
                  <img src={ImgElicitProgram2} alt="iOS App Development Services" />
                </li>
                <li>
                  <img src={ImgElicitProgram4} alt="iOS App Development Services" />
                </li>
                <li>
                  <img src={ImgElicitProgram5} alt="iOS App Development Services" />
                </li>
                <li>
                  <img src={ImgElicitProgram6} alt="iOS App Development Services" />
                </li>
              </ul>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <ul class="singal-gallery">
                <li>
                  <img src={ImgElicitProgram1} alt="custom web development" />
                </li>
                <li>
                  <img src={ImgElicitProgram3} alt="custom web development" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="elicitEventsfour sectionEvents">
        <div className="container">
          <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mb-3 mb-lg-0">
              <h2>Learning @Elicit</h2>
                  <h4>Expand your edge</h4>
                  <p>Learning is in our DNA. We commit to offering our people opportunities to acquire new skills and strengthen their readiness to advance into new roles.</p>
                  <h4>24/7 learning</h4>
                  <p>The world is at your fingertips with unlimited access to leading mobile-friendly online training curated by Elicit subject matter experts to help you advance at every level of your career.</p>
                  <h4>Feed your mind</h4>
                  <p>Explore our award-winning learning from Accenture’s experts and leading external providers like Coursera, Percipio, Pluralsight.</p>
                  <h4>Go beyond your edge</h4>
                  <p>No matter your function, industry or business expertise, take advantage of programs using our neuroscience-based learning principles and expansive educational opportunities that can help take you as far your ambition.</p>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <ul class="singal-gallery pb-2">
                <li>
                  <img src={ImgElicitLearning1} alt="custom web development" />
                </li>
              </ul>
              <ul class="image-gallery">
                <li>
                  <img src={ImgElicitLearning2} alt="custom web development" />
                </li>
                <li>
                  <img src={ImgElicitLearning3} alt="custom web development" />
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>
      <section className="elicitEventsfive sectionEvents">
        <div className="container">
          <div class="row">
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 d-none d-lg-block">
              <div className="text-vertical-align">"Good Times and Crazy Teammates makes the best memory, <br/> and we at Elicit always believes in Best"</div>
            </div>
            <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12 mb-3 mb-lg-0">
              <div class="row pb-3">
                <div class="col-lg-12 col-md-12 col-sm-12 p-0">
                  <h2>Fun Activities @Elicit</h2>
                  <p>It is always important to have fun at your workplace and Elicitians knows it pretty well as they don't miss out any oppurtunity to have different fun activities which doesnot just ligten the mood but also motivates team to deliver extra keeping the happiness as the priority.</p>
                  <p>We always seek chances to travel together may be a normal team day out, some hiking or trekking or a night stay at farm house. Different team building activities, fun events and reward and recognition is also an essential part of our core values.</p>
                </div>
                {/* <div class="col-lg-5 col-md-5 col-sm-12 p-0">
                  <ul class="singal-gallery">
                    <li>
                      <img src={ImgElicitFunActivities1} alt="img" />
                    </li>
                  </ul>
                </div> */}
              </div>
              <ul class="image-gallery">
                <li>
                  <img src={ImgElicitFunActivities1} alt="web development company in India" />
                </li>
                <li>
                  <img src={ImgElicitFunActivities2} alt="web development company in India" />
                </li>
                <li>
                  <img src={ImgElicitFunActivities3} alt="web development company in India" />
                </li>
                <li>
                  <img src={ImgElicitFunActivities4} alt="web development company in India" />
                </li>
                <li>
                  <img src={ImgElicitFunActivities5} alt="web development company in India" />
                </li>
                <li>
                  <img src={ImgElicitFunActivities6} alt="web development company in India" />
                </li>
              </ul>
            </div>
            {/* <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <ul class="singal-gallery">
                <li>
                  <img src={ImgElicitFunActivities4} alt="img" />
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>

    </>
  );
};
export default lifeAtElicit;
