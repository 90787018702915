import React from "react";
import {Helmet} from "react-helmet";
import "./blog.css";
import { Link } from "react-router-dom";
import chatgpt from "../../assets/img/chat-GPT.png";
import ImpactImg from "../../assets/img/Impacts.jpg";
import BenefitsImg from "..//../assets/img/adopting.png";
import EffectiveImg from "..//../assets/img/Realtime.jpg";
import AiTool from "..//../assets/img/AI_tool.jpg";
import ImportatnAi from "..//../assets/img/Importance.jpeg";
import Prosandcons from "..//../assets/img/Proscons.png";
import Emerging from "..//../assets/img/emerging.jpg";
import Iot from "..//../assets/img/iot.jpeg";
import Emergingtechnologies from "..//../assets/img/Emergingtechnologies.jpg"






const blog = () => {

  return (
    <div>
      <Helmet>
        <title>Latest blog on web, mobile app development, and UI/UX design</title>
        <meta name="title" content="Latest blog on web, mobile app development, and UI/UX design" />
        <meta name="keywords" content="web,mobile app development, UI/UX design, web, mobile app development" />
        <meta name="description" content="Explore insightful blogs on the web, mobile app development, and trends at Elicit Digital's. Stay updated with us and move forward in your industry." />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Latest blog on web, mobile app development, and UI/UX design" />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Explore insightful blogs on the web, mobile app development, and trends at Elicit Digital's. Stay updated with us and move forward in your industry." />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Latest blog on web, mobile app development, and UI/UX design" />
        <meta name="twitter:description" content="Explore insightful blogs on the web, mobile app development, and trends at Elicit Digital's. Stay updated with us and move forward in your industry." />
      </Helmet>
      <section className="service-header-carrier">
        <div>
          <div className="carrier_section">
            <h1 className="career_head"> Get the latest from infomation tech </h1>
            <p className="career_para">
              Start getting Up and coming programming languages and technology
            </p>
          </div>
          <div className="blog_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/how-to-integrate-chat-gpt"><img src={chatgpt} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/how-to-integrate-chat-gpt">How to integrate<br></br>chat GPT</Link></h3>
                    <p className="blog-description">Integrating a GPT chatbot into your website or application can be a relatively simple process, depending on your technical skills and the resources you have available Herer...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"><Link to="/artificial-intelligence-and-machine-learning"><img src={ImpactImg} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/artificial-intelligence-and-machine-learning">The Impact of AI and Machine Learning </Link></h3>
                    <p className="blog-description">AI and ML are two of the most exciting technologies to emerge in recent years. They're also applicable to Software development.....</p>
                    <div className="blog-date">
                      <span className="date">15 June 2021</span>
                      <span>10 min read</span>
                    </div>

                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"><Link to="/adopting-agile-methodologies-in-software-development"><img src={BenefitsImg} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/adopting-agile-methodologies-in-software-development">The Benefits of Agile Methodologies in SD</Link></h3>
                    <p className="blog-description">Agile methods are a group of software development practices that focus on improving productivity and quality by allowing the team to adapt to changes....</p>
                    <div className="blog-date">
                      <span className="date">15 June 2021</span>
                      <span>10 min read</span>
                    </div>

                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/effective-strategies"><img src={EffectiveImg} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/effective-strategies">Effective Strategies for Debugging and Code</Link></h3>
                    <p className="blog-description">Debugging and troubleshooting are crucial skills for developers, allowing them to identify and fix issues in their code...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/genertorAitools "><img src={AiTool} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/genertorAitools">Best free content genertor Ai tools</Link></h3>
                    <p className="blog-description">Jasper is the ultimate copywriting solution for busy content creators. Our AI-powered software enables you to generate high...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/differentindustries "><img src={ImportatnAi} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/differentindustries">How Ai will effort different industries</Link></h3>
                    <p className="blog-description">Artificial intelligence (AI) has the potential to significantly impact various industries by transforming processes, enhancing..</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/Prosandcons"><img src={Prosandcons} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/Prosandcons"> advantages and disadvantages Of AI</Link></h3>
                    <p className="blog-description">Artificial intelligence (AI) has numerous advantages and disadvantages.</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/Emerging"><img src={Emerging} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/Emerging"> Emerging Technologies in the IT Industry</Link></h3>
                    <p className="blog-description">AI is revolutionizing the IT industry by enabling machines to perform tasks...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>
              
                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/Internetofthings"><img src={Iot} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/Internetofthings"> Internet of Things (IoT)</Link></h3>
                    <p className="blog-description">IoT refers to the network of interconnected devices...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="box-blog">
                    <div className="blog-img"> <Link to="/Emergingtechnologies"><img src={Emergingtechnologies} alt="Blog Img" /></Link></div>
                    <div className="blog-by">By <a href="https://elicit.digital/">Elicit</a></div>
                    <h3><Link to="/Emergingtechnologies"> Emerging Technologies in the IT Industry</Link></h3>
                    <p className="blog-description">The IT industry is constantly evolving, and there are several emerging technologies...</p>
                    <div class="blog-date">
                      <span className="date">15 Jan 2022</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default blog;
