import { useState } from "react";
import axios from "axios";
const UseForm = (validate) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "", 
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("http://localhost:4000/user/contact-us", values)
    .then((res) => {
      console.log({ res });
      alert("Contact Has Been Submitted.");
    });
    setValues({
      name: "",
      email: "",
      mobile: "", 
      description: "",
    });
    console.log(values);
    setErrors(validate(values));
  };
  return { handleChange, values, handleSubmit, errors };
};

export default UseForm;
