import React from "react";
import "./blog.css";
import { Link } from "react-router-dom";
import adopting from "../../assets/img/adopting.png";
import chatGPT from "../../assets/img/chat-GPT.png";
import Impacts from "../../assets/img/Impacts.jpg";
import Real from "../../assets/img/Realtime.jpg";
import AI from "../../assets/img/AI_tool.jpg";
import Importance from "../../assets/img/Importance.jpeg";



const agileMethodologies = () => {

    return (
        <div>
            <section className="service-header-carrier">
                <div>
                    <div className="carrier_section">
                        <h1 className="career_head"> Make your store stand out from the others by... </h1>
                        <p className="career_para">
                            Start getting Up and coming programming languages and technology
                        </p>
                    </div>
                    <div className="section-blog-detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="detail-contentt">
                                        <div className="blog-detail-banner"><img src={adopting} alt="Blog Img" /></div>
                                    </div>
                                    <div class="blog-detail-top">
                                        <div class="technology-box">
                                            <a class="technology" href="#">Technology</a>
                                            <div class="blog-detail-by">By
                                                <a href="https://elicit.digital/"> Elicit Digital</a>
                                            </div>
                                        </div>
                                        <div class="blog-detail-box-date">
                                            <span class="blog-detail-date">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                                <a href="#">2021-06-04</a>
                                            </span>
                                            <span class="blog-detail-read">10 min read</span>
                                        </div>
                                        {/* <div class="blog-detail-like">
                      <a href="/"><i class="fa fa-bookmark-o" aria-hidden="true"></i></a>
                      <a href="/"><i class="fa fa-heart-o" aria-hidden="true"></i></a>
                    </div> */}
                                    </div>
                                    <div className="blog-detail-description">
                                        <h3>Introduction</h3>
                                        <p>Agile is a set of principles that guide the development of software. The Agile Manifesto was written in 2001 by a group of software developers who were frustrated with traditional methods and wanted to find a better way to build software. They believed that software should be developed incrementally, with frequent feedback from users and stakeholders along the way. The result is an iterative approach where teams work on small chunks of work at a time instead of trying to complete large projects all at once.</p>
                                        <p className="history-prg">Agile has become popular because it's effective at producing high-quality products quickly while reducing costs and risks for organizations that use it. It also helps companies develop better relationships between customers, developers and other stakeholders throughout the entire process - everyone gets involved early on so they can provide valuable input into what needs building next!</p>
                                        <h3>Agile Software Development Lifecycle</h3>
                                        <p>Agile Software Development Lifecycle
                                            The Agile Software Development Lifecycle consists of the following phases:</p>
                                        <ul>
                                            <li>Requirements Gathering - This phase involves gathering and documenting requirements for a new product or service. It also involves identifying stakeholders, who are people who will be affected by or have an interest in your project. As you gather requirements, you'll learn about what each stakeholder wants to achieve with your product or service. You'll use this information to create user stories that describe how users will interact with your software product once it's built (for example, "As a user I want to log into my account so that I can access my data").</li>
                                            <li className="history-prg">Design - In this phase, you'll think through how best to solve each problem identified during requirement gathering (such as how users should log into their accounts). You may create sketches or wireframes depicting what different screens might look like; these sketches help everyone involved visualize how things will work before any code is written</li>
                                        </ul>
                                        <h3>Agile Methodologies</h3>
                                        <p className="history-prg">
                                            Agile methods are a group of software development practices that focus on improving productivity and quality by allowing the team to adapt to changes quickly. The most common agile methodologies include Scrum, Kanban, Lean and Extreme Programming (XP).
                                            Scrum is a framework for developing products using small teams in an iterative approach. It requires the use of cross-functional teams that are self-organizing and empowered to make decisions without having to wait for approval from management or other stakeholders. Scrum also emphasizes transparency through continuous communication between all stakeholders involved in product development including customers/clients/users etc., so as to ensure that everyone knows what's going on at all times.
                                            Kanban was developed by Toyota Motor Corporation in Japan as part of their lean manufacturing strategy which aims at reducing waste while increasing efficiency & productivity through just-in-time production processes & eliminating overproduction (i.e., making only what is needed when it's needed). In software development terms this means limiting work items waiting for completion so there aren't any bottlenecks preventing progress being made towards reaching goals set out during sprint planning sessions every two weeks; doing this allows developers more time available each day which results in higher quality code being produced faster than would otherwise be possible if they were constantly interrupted by tasks coming up unexpectedly throughout each day instead!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div class="latest-post-section">
                                        <h3>Latest Post</h3>
                                        <div>
                                            <div class="latest-post-box">
                                                <div class="latest-img">
                                                    <img src={chatGPT} alt="Blog Img" />
                                                </div>
                                                <div class="latest-content">
                                                    <h6>
                                                        <a href="/how-to-integrate-chat-gpt">How to integrate
                                                            chat GPT</a>
                                                    </h6>
                                                    <div class="latest-date">
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            <a href="#">15 June 2021</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="latest-post-box">
                                                <div class="latest-img">
                                                    <img src={Impacts} alt="Blog Img" />
                                                </div>
                                                <div class="latest-content">
                                                    <h6>
                                                        <a href="/artificial-intelligence-and-machine-learning">The Impact of AI and Machine Learning
                                                        </a>
                                                    </h6>
                                                    <div class="latest-date">
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            <a href="#">15 June 2021</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="latest-post-box">
                                                <div class="latest-img">
                                                    <img src={Real} alt="Blog Img" />
                                                </div>
                                                <div class="latest-content">
                                                    <h6>
                                                        <a href="/effective-strategies">Effective Strategies for Debugging and Troubleshooting Code</a>
                                                    </h6>
                                                    <div class="latest-date">
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            <a href="#">15 June 2021</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="latest-post-box">
                                                <div class="latest-img">
                                                    <img src={AI} alt="Blog Img" />
                                                </div>
                                                <div class="latest-content">
                                                    <h6>
                                                        <a href="/genertorAitools">Best free content genertor Ai tools</a>
                                                    </h6>
                                                    <div class="latest-date">
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            <a href="#">15 June 2021</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="latest-post-box">
                                                <div class="latest-img">
                                                    <img src={Importance} alt="Blog Img" />
                                                </div>
                                                <div class="latest-content">
                                                    <h6>
                                                        <a href="/differentindustries">How Ai will effort different industries</a>
                                                    </h6>
                                                    <div class="latest-date">
                                                        <span>
                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                            <a href="#">15 June 2021</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                          
                                        </div>
                                    </div>
                                    <div class="section-stay-touch">
                                        <h3>Stay In Touch</h3>
                                        <div class="box-social-icon">
                                            <ul class="social-icon-list">
                                                <li>
                                                    <a href="https://www.facebook.com/ElicitInfotech"
                                                        target="/_blank"><i style={{ color: "#4867aa" }} class="fa fa-facebook-square" aria-hidden="true"></i></a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/Elicit_Digital"
                                                        target="/_blank" ><i style={{ color: "#1da1f2" }} class="fa fa-twitter-square" aria-hidden="true"></i></a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/elicit-digital/"
                                                        target="/_blank"><i style={{ color: "#007bb6" }} class="fa fa-linkedin-square" aria-hidden="true"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};
export default agileMethodologies;
