import React, { useState }  from "react";
import {Helmet} from "react-helmet";
import "./career.css";
//  import Career_Validation from "./Career Validation/Career_Validation";
// import Career_UseForm from "./Career Validation/Career_UseForm";
import carrier_img from "../../assets/img/carrier-img.png";
import Accordions from "./Accordion/Accordion";
import party1 from "../../assets/img/party1.jpg";
import party2 from "../../assets/img/party2.JPG";
import party3 from "../../assets/img/party3.jpg";
import party4 from "../../assets/img/party4.jpg";
import party5 from "../../assets/img/party5.jpg";
import hello from "../../assets/img/hello.png";
import training from "../../assets/img/training.png";
import team from "../../assets/img/team.png";
import reward from "../../assets/img/reward.png";
import culture from "../../assets/img/culture.png";
import activity from "../../assets/img/activity.png";
import Man2 from "../../assets/img/man-2.png";
import Man1 from "../../assets/img/man-1.png";
import { useEffect } from "react";
import axios from 'axios';


const Career = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    linkdinProfile: '',
    subject: '',
    file: null,
    message: '',
  });


  // error

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    linkdinProfile: '',
    subject: '',
    file: '',
  })
  
  const handleFileChange = (event) => {
    // console.log(event)
    const selectedFile = event.target.files[0];
    setFormData({ ...formData, file: selectedFile })
  };

  // Handle input change for text fields
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChanges = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 10);
    setFormData({ ...formData, [e.target.name]: numericValue });
  }
  // Handle file input change
  // const handleFileChange = (e) => {
  //   setFormData({ ...formData, file: e.target.files[0] });
  // };


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();


    const postData = new FormData();
    postData.append('name', formData.name);
    postData.append('email', formData.email);
    postData.append('mobileNumber', formData.mobileNumber);
    postData.append('linkdinProfile', formData.linkdinProfile);
    postData.append('subject', formData.subject);
    postData.append('file', formData.file);
    postData.append('message', formData.message);

    // Send the POST request using Axios
    axios
      .post('http://192.168.29.229:3002/api/enquiry', postData)
      .then((response) => {
        
        // Handle the response data
        console.log(response.data);
      })
       .catch((error) => {
        // Handle the error
        
        console.error(error);
       });


    // validtion error

    let validationErrors = {};
    let isValid = true;

    
    if (formData.name.trim() === '') {
      validationErrors.name = 'Please Enter your name.';
      isValid = false;
    }

    if (formData.email.trim() === '') {
      validationErrors.email = 'Please enter email address';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid.';
      isValid = false;
    }


    // if (formData.mobileNumber.trim() === '') {
    //   validationErrors.mobileNumber = 'mobileNumber is required.';
    //   isValid = false;
    // }


    // if (formData.subject.trim() === '') {
    //   validationErrors.subject = 'subject is required.';
    //   isValid = false;
    // }


    if (!formData.linkdinProfile.trim() === '') {
      validationErrors.linkdinProfile = "linkedin Required"
    } else if (!/\S+@\S+\.\S+/.test(formData.linkdinProfile)) {
      validationErrors.linkdinProfile = 'linkedin profile address is invalid';
    }


    setErrors(validationErrors);
       
    };
   

  return (
    <div>
      <Helmet>
        <title>Build Your Career Elicit Digital | Join Our Team now </title>
        <meta name="title" content="Build Your Career Elicit Digital | Join Our Team now " />
        <meta name="keywords" content="Build Your Career Elicit Digital, Join Our Team now" />
        <meta name="description" content="Explore exciting career opportunities at Elicit Digital! Join our team and get rewarding roles that match your skills. Start your journey with us today!" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Build Your Career Elicit Digital | Join Our Team now " />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Explore exciting career opportunities at Elicit Digital! Join our team and get rewarding roles that match your skills. Start your journey with us today!" />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="Build Your Career Elicit Digital | Join Our Team now " />
        <meta name="twitter:description" content="Explore exciting career opportunities at Elicit Digital! Join our team and get rewarding roles that match your skills. Start your journey with us today!" />
      </Helmet>
      <section className="service-header-carrier">
        <div >

          <div className="carrier_section">
            {/* <h1 className="career_head">Be a Part of Elicit Family </h1>
            <p className="career_para">
              To be the best place to work, we need the best people.
            </p> */}
          </div>
          <div className="company_container">
            {" "}
            <div className=" img_container d-flex justify-content-center ">
              <div className=" text_image ">
                <h5 className="card-title">
                  "If Opportunity doesn't Knock, Build a Door."
                </h5>
                <p className="card-text-para">-Milton Berle</p>
                <p className="card-text">
                  Join Elicit to be part of the change. Join us at our home away
                  from home where we are committed to working along with each
                  other for a common goal that is evolving constantly with the
                  latest technologies while taking utmost care of our most
                  valuable assets, our team members. Work with us and experience
                  the ‘Work-life balance’ and a happy workplace.
                </p>
              </div>
              <div className="img-fluid  ">
                <img src={carrier_img} className=" " alt="web development company" />
              </div>
            </div>
          </div>
          <Accordions />

          <div className=" container office_block">
            <h1 className="block-head">Life Inside Our Office</h1>
            <p className="block-para">
              We have achived success because of the skills, energy, and mindset
              of our people. And this is why we put so much emphasis on
              combining them to create the best Professionals. We attract
              creative, skilled professionals who think out-of-the-box and will
              help you grow.
            </p>

            <div className="container party_img_colarge">
              <div className="row">
                <div className=" col-lg-6 col-sm-12 col-md-12">
                  <img className="party_img5" src={party5} alt="ios development" />
                </div>

                <div className=" col-lg-3 col-md-6 col-sm-6">
                  <div className="">
                    <img className="party_img" src={party4} alt="office party" />
                  </div>

                  <div className="">
                    <img className="party_img" src={party3} alt="weekend party" />
                  </div>
                </div>
                <div className=" col-lg-3 col-md-6 col-sm-6">
                  <div className="">
                    <img className="party_img" src={party2} alt="office weekend party" />
                  </div>

                  <div className="">
                    <img className="party_img" src={party1} alt="weekend party" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="background_color_career"   >
        <div className="container">
          <div className="carrier-bottom">
            <div className=" text_colarge">
              <div className="row text_colarge_one">
                <div className="col-sm-12 col-md-12 col-lg-4 text_colarge_two">
                  <h1 className="perk_head">Perks & Benefits </h1>
                  <p className="perk_text_para">
                    At Elicit, we foster and help our team member to grow and
                    achive their goals. We Believe this approach helps us succeed
                    as a Community.
                  </p>
                  <p className="perk_text_para">
                    In addition to the work-life balance and flexible schedules,
                    we offer a variety of Perks and benefits that keep our Team on
                    the cutting edge of their field. this is for all the
                    innovators and storytellers out there.
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 text_colarge_two ">
                  <div className="row text_row">
                    <div className="text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={hello} alt="ios development company" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">5 Days a Week</h3>
                        <p className="perk_para">
                          To promote work-life balance and mental place, all
                          weekends are off.
                        </p>
                      </div>
                    </div>

                    <div className=" text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={training} alt="web development company in indore" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">Trainings</h3>
                        <p className="perk_para">
                          Inhence our Knowledge and skills with the help of our
                          experience team members.
                        </p>
                      </div>
                    </div>

                    <div className="text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={team} alt="digital marketing company in indore" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">Team Building</h3>
                        <p className="perk_para">
                          Indoor & Outdoor team Building workshop and Activities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 text_colarge_two ">
                  <div className="row text_row">
                    <div className=" text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={reward} alt="ux design" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">Rewards</h3>
                        <p className="perk_para">
                          get Rewarded for your hard Work, dedicated and
                          Commitment.
                        </p>
                      </div>
                    </div>

                    <div className=" text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={culture} alt="ui/ux design" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">Flexible Work Culture</h3>
                        <p className="perk_para">
                          To empower our Team Menbers will the skill to be
                          effecient in a fast-paced life.
                        </p>
                      </div>
                    </div>

                    <div className=" text_column1">
                      <div className="our_benefits">
                        <img className="perk_img" src={activity} alt="web developer" />
                      </div>
                      <div>
                        <h3 className="perk_smallhead">
                          Recreational Activities
                        </h3>
                        <p className="perk_para">
                          Come play pool, table Tenni, cricket or anything else to
                          refresh your mind.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="background_color_career form-bg-gray pt-0"   >

        <div className="container">
          <div className="career_form_container ">
            <p className="career-page-paragraph">Search open places that match your abilities and interest. We search for energetic, inquisitive, inventive and arrangement driven cooperative people.</p>

            <div className="row">
              <ul>
                <li className="circle"></li>
                <li className="circle"></li>
                <li className="circle"></li>
                <li className="circle"></li>

              </ul>
              <div className="col-sm-3 col-md-3 col-lg-3 form_bg_img text-center">
                <img src={Man1} alt="website development company" />

              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 form_bg_clr" >
                <form onSubmit={handleSubmit}>
                  <h1 className="side_head_work">Let's Work Together</h1>
                  <div className="form-row_carrier">
                    <div className="form-group col-sm-6">
                      <label for="inputname "></label>
                      <input
                        type="text"
                        className="form-control_carrier"
                        id="inputname"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                         {errors.name && (
                        <span className="required_carrier">{errors.name}</span>
                      )}    
                    </div>

                    <div className="form-group col-sm-6">
                      <label for="inputEmail"></label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="email"
                        
                      />
                         {errors.email && (
                        <span className="required_carrier">{errors.email}</span>
                      )}   
                    </div>

                    <div className="form-group col-sm-6">
                      <label for="inputNumber"></label>
                       <input
                        type="text"
                        className="contact_input"
                        placeholder="Mobile"
                        aria-label="mobile"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChanges}
                      /> 
                      
                       {errors.mobileNumber && (
                        <span className="required_carrier">
                          {errors.mobileNumber}
                        </span>
                      )}   
                    </div>

                    <div className="form-group col-sm-6">
                      <label for="linkedin_profile"></label>
                      <input
                        type="text"
                        className="form-control_carrier"
                        id="linkedin_profile"
                        placeholder="Linkedin Profile"
                        value={formData.linkdinProfile}
                        name="linkdinProfile"
                        onChange={handleChange}
                       
                      />
                      {errors.linkdinProfile && (
                        <span className="required_carrier">
                          {errors.linkdinProfile}
                        </span>
                      )}   
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="inputAddress"></label>
                    <input
                      type="text"
                      className="form-control_subject"
                      id="inputAddress"
                      placeholder="Subject*"
                      value={formData.subject}
                      name="subject"
                      onChange={handleChange}
                    />
                       {errors.subject && (
                      <span className="required_carrier">
                        {errors.subject}
                      </span>
                    )}    
                  </div>
                   <div className="form-group">
                     <input
                      className="form-control_subject"
                      type="file"
                      name="file"
                      onChange={(e) => handleFileChange(e)}
                    /> 
                     {/* {errors.file && (
                      <span className="required_carrier">
                        {errors.file}
                      </span>
                    )}  */}
                  </div> 
                  <div className="form-group">
                    <textarea
                      className="form-control_text"
                      placeholder="Message*"
                      id="message"
                      value={formData.message}
                      name="message"
                      onChange={handleChange}
                      autoComplete="off"
                    ></textarea>
                    {/* {errors.message && (
                      <span className="required_carrier">
                        {errors.message}
                      </span>
                    )}  */}
                  </div>
                  <button type="submit " className="btn">
                    Apply
                  </button>
                </form>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 form_bg_img text-center">
                <img src={Man2} alt="android app developers" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="background_color_career pt-0"   >
        <div className="container">
          <div className="thought container">
            {" "}
            <div className="carrier_slogan">
              <h2 className="carrier_slogan_head1">
                “The only way to achieve the impossible is to believe it is possible”
              </h2>
              <h4 className="carrier_slogan_head2">
                <span>-Charles Kingsleigh</span>
              </h4>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};
export default Career;
