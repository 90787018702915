import React from "react";
import { useState, useEffect } from "react";
import './Gotop.css'


const GoTo = (props) => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };


    return (
        <>
            <div className="gotop-btn">
                {showButton && (
                    <button onClick={scrollToTop} className="back-to-top">
                        &#8679;
                    </button>
                )}
            </div>


        </>
    );
};
export default GoTo;