import React from "react";
import {Helmet} from "react-helmet";

import "./AndroidDevelopment.css";
import "./animate.css";

import Highfidelity from "../../assets/img/usingsoftware.png"
import Whatdo from "../../assets/img/Whatdo.png"
import DigitalExperience from "../../assets/img/DigitalExperience.jpg"
import wairefame from "../../assets/img/wairfame1.png"
import left from "../../assets/img/section-divider-bg-left.png"
import Dividerright from "../../assets/img/section-divider-bg-right.png"
import SoftwareRedesign from "../../assets/img/SoftwareRedesign.jpg"
import TechnicalDesign from "../../assets/img/TechnicalDesign.jpg"
import CrossPlatform from "../../assets/img/CrossPlatform.jpg"
import MobileWeb from "../../assets/img/MobileWeb.jpg"


const UiUxDevelopment = () => {
  return (

    <div>
      <Helmet>
        <title>UI/UX Design Services | Graphic Design Agency</title>
        <meta name="title" content="UI/UX Design Services | Graphic Design Agency" />
        <meta name="keywords" content="UI/UX Design Services, Graphic Design Agency, graphic design services " />
        <meta name="description" content="Elicit offers you a variety of graphic design services at the very best prices. If you are looking for UI/UX Design services near you, contact us today!" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="UI/UX Design Services | Graphic Design Agency " />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Elicit offers you a variety of graphic design services at the very best prices. If you are looking for UI/UX Design services near you, contact us today!" />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="UI/UX Design Services | Graphic Design Agency " />
        <meta name="twitter:description" content="Elicit offers you a variety of graphic design services at the very best prices. If you are looking for UI/UX Design services near you, contact us today!" />
      </Helmet>
      <section className="bg_img">
      </section>
      <section className="service-banner">
        <div className="col-lg-12 offset-lg-12 col-md-12" >
          <div className="section-title-area text-center">
            <h1 className="section-title-main">UI/UX Design  <span class="technolgy_focus">Services</span></h1>
            <p className="services-text">Elevate Your Digital Experience with Expert UI UX Designers</p>
            <div className="service-contact text-center" >
              <a class="cont-btn" href="/get-in-touch">Contact Us</a>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-success-area section">
        <div className="container">
          <div className="we-ui ">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="ui-heading-text">
                  <span className=""><h2 className="ui-text-heading">Transform Your Digital Experience with Elicit's Customized Design Solutions</h2></span>
                  <p className="gathering-text-prag"> At Elicit, we understand that designing a website or application is more than just making it look good. It's about creating a seamless experience for your visitors from start to finish. That's why our team of expert designers collaborates with you every step of the way to ensure that your site not only adapts and accesses information easily but also wows visitors at first glance. We understand that every business and brand is unique, which is why we offer fully customized services tailored specifically to meet your needs. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="">
                  <img src={DigitalExperience} alt="UI/UX Design Services" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="what-main-section  ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="main-we-heading">
                <div className="we-heading">
                  <h3>What we do</h3>
                </div>
                <div>
                  <p className="we-text-ui">We carefully consider every pixel on a site to ensure that it contributes to an exceptional user experience. Our team strategically utilizes and tests UX strategies to help users accomplish their goals on your website or app, whether that means making a purchase or signing up for a newsletter. We believe in identifying what's most important for the user and paving the way for them to achieve that goal seamlessly. </p>
                  <p className="we-text-ui">Once the information architecture and wireframe are complete, we turn our focus towards creating meaningful emotional connections through UI design that resonates with your brand. Everything we do is geared towards making sure users feel heard, understood, and supported while using your platform- because when they succeed, you succeed too!	</p>
                  <div className="main-creative">
                    <ul className="list-creative-main">
                      <li>Creative direction</li>
                      <li>Strong layout and graphic redesign</li>
                      <li>Content analysis and generation</li>
                      <li>Creative direction</li>
                      <li>Usability</li>
                      <li>Information architecture</li>
                      <li>Database integration</li>
                      <li>Online branding</li>
                      <li>Creative online advertising solutions </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="">
                <img src={Whatdo} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* second section */}
      {/* new section */}
      <section className="design-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="Desgin-heading">
                <h4 className="section-title-ui">Design the Future With elicit UI/UX for Emerging Tech</h4>
              </div>
            </div>
          </div>
          <div className="main-features">
            <div className="row">
              <div className="col-lg-4">

                <div className="sigle-feature-Blockchain">
                  <div className="media">
                    <div className="iconfuture-4">1</div>
                    <div className="media-body">
                      <h3 className="digital-title mt-0 ">Blockchain Technology </h3>
                      <p className="design-content">Whether it is an App or smart contract, we know how to design for security, scalability, and transparency.</p>
                    </div>
                  </div>
                </div>

              </div>
              {/* second */}
              <div className="col-lg-4">

                <div className="sigle-feature-Blockchain">
                  <div className="media">
                    <div className="iconfuture-4">2</div>
                    <div className="media-body">
                      <h3 className="digital-title mt-0 ">Artificial Intelligence  </h3>
                      <p className="design-content">By leveraging intelligent computing, we build AI-driven UX that streamlines and augment human-centered experiences.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* thrid */}
              <div className="col-lg-4">

                <div className="sigle-feature-Blockchain">
                  <div className="media">
                    <div className="iconfuture-4">3</div>
                    <div className="media-body">
                      <h3 className="digital-title mt-0 ">Augmented Reality </h3>
                      <p className="design-content">Our team creates human-focused user interfaces for Augmented Reality apps that seamlessly blend virtual objects into the real world.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* fourth */}
              <div className="col-lg-4">

                <div className="sigle-feature-Blockchain">
                  <div className="media">
                    <div className="iconfuture-4">4</div>
                    <div className="media-body">
                      <h3 className="digital-title mt-0 ">Mobile & Web Apps</h3>
                      <p className="design-content">We put a strong emphasis on implementing UI/UX design principles to create user-driven designs for mobile and web apps</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* five */}
              <div className="col-lg-4">

                <div className="sigle-feature-Blockchain">
                  <div className="media">
                    <div className="iconfuture-4">5</div>
                    <div className="media-body">
                      <h3 className="digital-title mt-0 ">Internet of Things </h3>
                      <p className="design-content">We develop customized user experiences that cater to each user’s requirements and expectations. With IoT applications, we ensure seamless connectivity between devices through innovative functionality that makes life easier for users.</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section className="new-ui-design container pt-5">

        <div className="wave-row">
          <h3 className="section-title-ios text-center">Our UI/UX Design Services</h3>
          <div className="row pt-5">
            <div className="col-lg-7 col-md-6">
              <div className="main-Gathring">
                <div className="High-image">
                  <img src={Highfidelity} alt="UI/UX Design Services" />
                </div>
              </div>

            </div>

            <div className="col-lg-5 col-md-6">
              <div className="main-content">
                <h5 className="text-gathering text-left">High-fidelity Prototype</h5>
                <p className="gathering-text-prag">As experts in the field of app development,
                  we understand that conceptualizing an idea is only half the battle.
                  That's why we create high-fidelity prototypes to demonstrate how your
                  application would behave and function in reality. Our fully-interactive
                  prototypes give you a comprehensive understanding of how your project will
                  flow from start to finish, allowing you to visualize every detail before
                  committing to any final decisions.</p>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="arrow-left new-ui-design">
        <img src={left} alt="UI/UX Design Services" />
      </section>



      {/* second row */}

      <section className=" second-Wireframes container">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="main-content-wireframes">
              <h5 className="text-gathering text-left">Wireframes</h5>
              <p className="gathering-text-prag">Wireframes are the backbone of every successful digital product and we understand that better than anyone. Our team of experts has spent countless hours perfecting the art of wireframing to create an effective outline for your mobile and web app.</p>

            </div>
          </div>


          <div className="col-lg-7 col-md-6">
            <div className="main-Gathring">
              <div className="High-image">
                <img src={wairefame} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="arrow-left new-ui-design">
        <img src={Dividerright} alt="UI/UX Design Services" />
      </section>


      {/* thrid */}
      <section className=" second-Wireframes container">
        <div className="row">
          <div className="col-lg-7 col-md-6 order-1">
            <div className="main-Gathring">
              <div className="High-image">
                <img src={MobileWeb} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6">
            <div className="main-content-mobile">
              <h5 className="text-gathering text-left">Mobile and Web User Interface Design</h5>
              <p className="gathering-text-prag">Our mission is to ensure that your ideas and thoughts are transformed into a clear, fully functional design that complies with what the user expects and understands.</p>
            </div>
          </div>



        </div>
      </section>


      <section className="arrow-left new-ui-design">
        <img src={left} alt="UI/UX Design Services" />
      </section>



      {/* foruth  */}
      <section className=" second-Wireframes container">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="main-content-cross">
              <h5 className="text-gathering text-left">Cross-Platform Compatibility</h5>
              <p className="gathering-text-prag">We ensure that our designs are not only visually engaging but also highly functional across different platforms. Our team works tirelessly to ensure that every aspect of the user interface is optimized for responsiveness and usability, regardless of whether it's being accessed from a desktop computer or a mobile device.</p>

            </div>
          </div>


          <div className="col-lg-7 col-md-6">
            <div className="main-Gathring">
              <div className="High-image">
                <img src={CrossPlatform} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="arrow-left new-ui-design">
        <img src={Dividerright} alt="UI/UX Design Services" />
      </section>

      <section className=" second-Wireframes container">
        <div className="row">
          <div className="col-lg-7 col-md-6 order-1">
            <div className="main-Gathring">
              <div className="High-image">
                <img src={TechnicalDesign} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6">
            <div className="main-content-technical">
              <h5 className="text-gathering text-left">Technical Design</h5>
              <p className="gathering-text-prag">Our team prides itself on providing our clients with a comprehensive understanding of their project's technical workflow. Through meticulous planning and analysis, we create a detailed blueprint that outlines the architecture and technology stack required for each component of the application.</p>
            </div>
          </div>



        </div>
      </section>


      <section className="arrow-left new-ui-design">
        <img src={left} alt="UI/UX Design Services" />
      </section>

      <section className=" second-Wireframes container">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <div className="main-content">
              <h5 className="text-gathering text-left">Software Redesign</h5>
              <p className="gathering-text-prag">With our expert team, we understand that first impressions matter. If you have a website or app that looks outdated, unappealing, or simply doesn't reflect your brand's values, our Software Redesign service can make all the difference for your business.</p>

            </div>
          </div>


          <div className="col-lg-7 col-md-6">
            <div className="main-Gathring">
              <div className="High-image">
                <img src={SoftwareRedesign} alt="UI/UX Design Services" />
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>







  );
};
export default UiUxDevelopment;
