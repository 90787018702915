import React from "react";
import {Helmet} from "react-helmet";
import "./About.css";
import { Link } from "react-router-dom";
import instagram from "../../assets/img/instagram-blue.svg";
import facebook from "../../assets/img/facebook-blue.svg";
// import mail from "../../assets/img/mail.svg";
import SmallSlider from "../About/Small Slider/SmallSlider";
import LogoIcon from "../../assets/img/logo-img.jpg";

import Ser from "../../assets/img/Elicit-Logo.png";
import Pdi from "../../assets/img/Project-done-icon.png";
import countries from "../../assets/img/countries.png";
import Hc from "../../assets/img/Happy-Customers.png";
import Fte from "../../assets/img/Full-time-employe.png";
import Ye from "../../assets/img/Years-exp.png";
import Mhp from "../../assets/img/man-hours-project.png";
// import { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";

const About = () => {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);
  return (
    
    <div className="">
      <Helmet>
        <title>Web Design and Development Company | Hire Our Developers</title>
        <meta name="title" content="Web Design and Development Company | Hire Our Developers" />
        <meta name="keywords" content="Web Design and Development Company , Hire Our Developers, web development" />
        <meta name="description" content="Learn about our mission, values, and commitment to delivering excellence in web development. Explore our story and meet the passionate minds with Elicit Digital." />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta property="og:title" content="Web Design and Development Company | Hire Our Developers" />
        <meta property="og:site_name" content=" Elicit Digital-Web Development Company" />
        <meta property="og:url" content="www.elicit.digital" />
        <meta property="og:description" content="Learn about our mission, values, and commitment to delivering excellence in web development. Explore our story and meet the passionate minds with Elicit Digital." />
        <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta name="twitter:title" content="About Us | Elicit Digital " />
        <meta name="twitter:description" content="Learn about our mission, values, and commitment to delivering excellence in web development. Explore our story and meet the passionate minds with Elicit Digital." />


      </Helmet>
      <div className="service-Aboutheader ">
        <div class=" u_container u_container--main first-section-about">
          <div className="about_bgpage">
            <div className="container">


              {" "}
              <div class="row top-section">
              <div class="about-heading">
                <div class="col-lg-12 col-md-12 col-sm-12 centered-section" >
                  <h1 className="centered-section-head" >Who We Are</h1>
                  <h3 class="description-about-para">
                  Team that empower businesses by building them high-quality custom software.
                  </h3>
                  <div class="centered-section">
                    <p class="p-text">
                    We build robust solutions for startups that helps their business idea real and profitable. Every project is unique, and we find it amazing because only a custom product can become your complete solution to digital and live business challenges. For us, being coding experts is like working out a muscle. The more you work, the more powerful you get.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="background-color">
          <div className="container">
            <div className="row">
              
              <div class=" col-lg-6 col-md-12 col-sm-12 people-info-section ">
                <div className="about-logo">
                <div class="boxLoadingblack"></div>
                <div class="boxLoadingyellow"></div>
                <div class="boxLoadingreen"></div>
                <div class="boxLoadingblue"></div>
                <div class="boxLoadingpink"></div>
                <img src={Ser} alt="Hybrid Mobile App Development Company" />
                <p className="description-text">
                  Every year we grow stronger and bigger, with all the talented
                  individuals who want to grow themselves and change the world
                  for the better. Talent, thirst for knowledge, and practice –
                  this combination is always winning, no matter what.
                </p>
              </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 people-info-sectionNumber" >

           <div className=" Elicit-in-Numbers">
<div className="section-dwon">
<div class="boxLoadingpink"></div>
<div class="boxLoadingblue"></div>
<div class="boxLoadingyellow"></div>
<div class="boxLoadingblack"></div>
                <div className="span-number two-box">
                <img src={countries} alt="Hybrid Mobile App Development Company" />
                <br/>
                  <span className="number counting" data-count="160">160+</span>{" "}   <br/>
                  <span className="caption">successful projects</span>
                </div>
                <div className="span-number one-box">
                <img src={Pdi} alt="Hybrid Mobile App Development Company" />
                <br/>
                  <span className="number counter" data-target="110">110+</span>{" "}
                  <br/>
                  <span className="caption">Experts in the team</span>
                </div>
                <div className="span-number tree-box">
                  <div className="center-box">
                <img src={Hc} alt="Hybrid Mobile App Development Company" />
                <br/>
                  <span className="number">450+</span>{" "}  <br/>
                  <span className="caption">Happy Customers</span>
                </div>
                </div>
                <div className="span-number for-box">
                <img src={Fte} alt="Hybrid Mobile App Development Company" />
                <br/>
                  
                  <span className="number">50+</span>{" "}<br/>
                  <span className="caption">Full time Employees</span>
                </div>{" "}
                <div className="span-number five-box">
                <img src={Ye} alt="Hybrid Mobile App Development Company" />
                <br/>
                 
                  <span className="number">10+</span>{" "}<br/>
                  <span className="caption">years Experience</span>
                </div>
                <div className="span-number six-box">
                <img src={Mhp} alt="Hybrid Mobile App Development Company" />
                <br/>
                  
                  <span className="number">4.5M+</span>{" "}     <br/>
                  <span className="caption">Man Hours project Work Done</span>
                </div>
                {/* <div className="span-number">
                  <span className="caption">
                    Projects for UN, WHO, MDTU, etc.
                  </span>
                </div> */}
                </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          {/* <div className="container">
            <div className="team-section" >
              <h2>Our Team</h2>
              <div className="d-flex justify-content-center row">
              <div className="team__image-wrapper col-lg-3 col-md-6  col-sm-6">
                  <Link to="#" className="team__image">
                    <img
                      src={LogoIcon}
                      alt="elicit digital"
                    />
                  </Link>

                  <h4>Ankita</h4>
                  <h5>Co-Founder</h5>
                   <p>
                    Being CEO is not difficult when your team consists of great
                    people who also happen to be great specialists in their
                    fields of expertise.
                  </p> 
                </div>
                <div className="team__image-wrapper col-lg-3 col-md-6 col-sm-6">
                  <Link to="#" className="team__image">
                    <img
                      src={LogoIcon}
                      alt="Mobile application and web developemnt company in indore"
                    />
                  </Link>
                  <br />
                  <h4>Harsh</h4>
                  <h5>Senior Software Engineer</h5>
                   <p>
                    As a business owner, I know that the company's greatest
                    asset is the team. Everyone in my team is great, skilled,
                    and talented.
                  </p> 
                </div>

              
                <div className="team__image-wrapper col-lg-3 col-md-6  col-sm-6">
                  <Link to="#" className="team__image">
                    <img
                      src={LogoIcon}
                      alt="Digital marketing agency and website development company"
                    />
                  </Link>
                  <h4>Abhay</h4>
                  <h5> Senior UI Developer</h5>
                   <p>
                    Even though I'm in software development for more than 8
                    years, my passion for creating digital solutions only grows
                    each year.
                  </p> 
                </div>

                <div className="team__image-wrapper col-lg-3 col-md-6  col-sm-6">
                  <Link to="#" className="team__image">
                    <img
                      src={LogoIcon}
                      alt="iso application development services"
                    />
                  </Link>
                  <h4>Nitin </h4>
                  <h5>Senior Software Engineer</h5>
                   <p>
                    I'm excited to be in charge of the financial side of such a
                    strong and continuously growing company as Powercode.
                  </p> 
                </div>
              </div>

              <h3 className="all-team">
                <Link className="team-section-link " to="/ourTeam">
                  {" "}
                  View Team{" "}
                </Link>
              </h3>
            </div>
          </div> */}
          <div className="t-views">
            <div className="container">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h1 className="t-views-head">Сlients about our team</h1>
                <h3 className="t-view-datail">
                  {/* The whole Powercode team was very well coordinated. They
                  offered and implemented highly innovative solutions I’d never
                  even thought about. The Level UP website, and first in Ukraine
                  mobile app for networking and matching projects were
                  completely designed and developed by the Powercode team. If
                  you need a digital product that will become #1 worldwide –
                  you’ve found your partner in crime. */}
                  The whole Elicit team was very well coordinated. They offered and implemented highly innovative solutions I’d never even thought about. The 24alife website, and first in Slovenia mobile app for health and fitness tracking were completely designed and developed by the Elicit team. If you need a digital product that will become #1 worldwide – you’ve found your partner in crime.
                </h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="flex-container row">
              <div className="col-lg-6 slider-info"       >          <h1>Our Life & Work</h1>
                <p>
                  {/* A This is where we play, relax, communicate, and of course
                  work on projects. */}
                  Advance your abilities through our cutting edge contributions that tie virtual advancing
                  straightforwardly back to your genuine vocation. Key areas of learning include
                </p>
                <p> Check our Instagram for more.</p>
                <div className="social-links-container_about">
                  <a href="https://www.instagram.com/elicit.digital/"
                    target="_blank"
                    className="social-link"
                  >
                    <img src={instagram} alt="Hybrid Mobile App Development Company" />
                  </a>
                  <a
                    href="https://www.facebook.com/ElicitInfotech/"
                    target="_blank"
                    className="social-link"
                  >
                    <img src={facebook} alt="best digital marketing company" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/elicit-digital/ "
                    target="_blank"
                    className="social-link"
                  >
                    {/* <img src={mail} alt="digital marketing services" /> */}
                    <i  class="fa fa-linkedin  linkdincolor" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 img_slider">
                <SmallSlider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
